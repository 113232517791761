import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-info-card',
	templateUrl: './info-card.component.html',
	styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
	@Input() title = 'Title';
	@Input() subtitle = 'SubTitle';
	@Input() link = '#';
	@Input() iconName = 'domain';

	constructor(private _router: Router) {}

	navigateToReport() {
		this._router.navigate([this.link]);
	}
}
