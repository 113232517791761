import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EndpointsService } from '../endpoints.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfile } from 'src/app/core/models/auth.models';
import { StashService } from 'src/app/utilities/services/stash.service';
import { ProfileService } from './profile.service';
import { first, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProfileHttpService {
	private readonly _profileUrl = environment.profileUrl;
	private readonly _profilePictureUrl = environment.profilePictureUrl;
	profilePicture$ = new BehaviorSubject<string>('');

	constructor(
		private _httpClient: HttpClient,
		private _endpoints: EndpointsService,
		private _stashService: StashService,
		private _profileService: ProfileService
	) {}

	async callUsersProfilePicture(userOid: string) {
		const profilePictureBase64 = this._stashService.getProfilePicture(userOid);
		if (profilePictureBase64) {
			return await new Promise<string>((resolve) => {
				resolve(profilePictureBase64);
			});
		}

		const finalProfilePictureUrl = this._profilePictureUrl.replace('[user_oid]', userOid);
		return await this._httpClient
			.get(finalProfilePictureUrl, { responseType: 'blob' })
			.toPromise()
			.then((data) => {
				return new Promise((resolve, _) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.readAsDataURL(data);
				});
			})
			.then((data: string) => {
				this._stashService.setProfilePicture(userOid, data);
				return new Promise((resolve, _) => {
					resolve(this._stashService.getProfilePicture(userOid));
				});
			})
			.catch((err) => {});
	}

	callUsersProfile() {
		return this._httpClient.get(this._profileUrl);
	}

	callMyAccessProfile(): Observable<UserProfile> {
		const currentUserProfile = this._profileService.myAccessProfile$.getValue();
		if (!currentUserProfile) {
			console.log('Downloading User Profile');
			const url = this._endpoints.getMyAccess();
			return this._httpClient
				.get<UserProfile>(url)
				.pipe(tap((profile) => this._profileService.setMyAccessAccount(profile)));
		}
		console.log('User Profile Already Exists');
		return this._profileService.myAccessProfile$.pipe(first());
	}
}
