<div [formGroup]="form" *ngIf="filter">
	<div [ngSwitch]="filter.controlType">
		<input
			*ngSwitchCase="'textbox'"
			[id]="filter.key"
			[formControlName]="filter.key"
			[type]="filter.type"
			[placeholder]="filter.label"
			aria-label="input-dynamic"
		/>

		<mat-form-field *ngSwitchCase="'dropdown'" appearance="outline" class="filter">
			<mat-select [id]="filter.key" [formControlName]="filter.key" (selectionChange)="onChange()" value="">
				<mat-option value="" class="select-option">{{ filter.label }}</mat-option>
				<mat-option *ngFor="let option of filter.options" [value]="option.key" class="select-option">
					{{ option.value }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-chip-list
			*ngSwitchCase="'radio'"
			[id]="filter.key"
			[formControlName]="filter.key"
			[value]="filter.value"
			(change)="onChange()"
		>
			<mat-chip class="body-2" *ngFor="let option of filter.options" [value]="option.key">
				{{ option.value }}
			</mat-chip>
		</mat-chip-list>

		<mat-checkbox
			*ngSwitchCase="'checkbox'"
			[id]="filter.key"
			[formControlName]="filter.key"
			color="primary"
			class="body-1"
			[checked]="filter.value"
			(change)="onChange()"
		>
			{{ filter.label }}
		</mat-checkbox>

		<button *ngSwitchCase="'button'" [id]="filter.key" mat-flat-button color="primary" (click)="filter.callback()">
			<span class="button" style="padding-right: 4px">{{ filter.label }}</span>
			<mat-icon>refresh</mat-icon>
		</button>

		<section *ngSwitchCase="'date-range'" class="date-range-container">
			<span class="body-1 text-dark-2 selector-spacing">{{ filter.label }}</span>
			<app-date-range-selector [dateRangeKey]="dateRangeKey"></app-date-range-selector>
		</section>
	</div>
</div>
