// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MessagesComponent } from './utilities/messages/messages.component';
import { LatLongDialogComponent } from './utilities/dialogs/lat-long-dialog/lat-long-dialog.component';
import { ConfirmDialogComponent } from './utilities/dialogs/confirm-dialog/confirm-dialog.component';
import { StarbarComponent } from './utilities/dialogs/starbar/starbar.component';
import { LogoutComponent } from './utilities/dialogs/logout/logout.component';
import { SettingsComponent } from './settings/settings.component';

// Imports
import { InterceptorService } from './interceptor.service';
import { MaterialModule } from './core/material.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';

// MSAL
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import {
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalRedirectComponent,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';

const MSALLogger = (loglevel: any, message: any) => console.log(message);

const MSALInstanceFactory = (): IPublicClientApplication => {
	return new PublicClientApplication({
		auth: {
			clientId: environment.clientId,
			authority: environment.authority,
			redirectUri: environment.redirectUri,
			navigateToLoginRequestUrl: false,
		},
		cache: {
			cacheLocation: 'localStorage',
		},
		system: {
			loggerOptions: {
				loggerCallback: MSALLogger,
				logLevel: LogLevel.Info,
			},
		},
	});
};

const MSALGuardConfigFactory = (): MsalGuardConfiguration => {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: { scopes: ['profile openid'] },
		loginFailedRoute: '/home',
	};
};

// Creates and adds access tokens for all calls with the set url
const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
	const protectedResourceMap = new Map<string, string[]>();

	protectedResourceMap.set(`${environment.webApiUrl}/*`, ['profile openid']);
	protectedResourceMap.set(environment.profilePictureUrl, ['User.Read.All', 'User.ReadWrite.All']);
	return { interactionType: InteractionType.Redirect, protectedResourceMap };
};

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		MessagesComponent,
		LatLongDialogComponent,
		ConfirmDialogComponent,
		StarbarComponent,
		LogoutComponent,
		SettingsComponent,
	],
	imports: [
		BrowserAnimationsModule,
		FlexLayoutModule,
		LayoutModule,
		BrowserModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		MaterialModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatSnackBarModule,
		OverlayModule,
	],
	providers: [
		MsalGuard,
		MsalService,
		MsalBroadcastService,
		{ provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
		{ provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
		{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
		{ provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
		{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				duration: 5000,
				verticalPosition: 'top',
				horizontalPosition: 'end',
				panelClass: ['mat-accent'],
			},
		},
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
