import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { EndpointsService } from './endpoints.service';
import { HttpClient } from '@angular/common/http';
import { formatDistance } from 'date-fns';
import {
	ServiceStructureLookup,
	BusinessUnitLookup,
	ServiceChannelLookup,
	TemplateField,
	ServiceLookup,
} from '../models/domain.models';
import { TemplateFieldType } from '../models/enumerations';
import {
	ServiceStructBundleDto,
	BusinessUnitStructDto,
	ServiceChannelStructDto,
	ServiceChannelPrototypeStructDto,
	TemplateStructDto,
	ServiceStructDto,
	ServicePrototypeStructDto,
} from '../models/service-structure-dto.models';

@Injectable({ providedIn: 'root' })
export class ServiceStructureService {
	constructor(private endpoints: EndpointsService, private http: HttpClient) {}

	loadServiceStructure(): Observable<ServiceStructureLookup> {
		return this.http
			.get<ServiceStructBundleDto>(this.endpoints.getServiceStructureEndpoint())
			.pipe(
				map((dto) => this.convertServiceStructureBundleDto(dto)),
				shareReplay()
			);
	}

	private convertBusinessUnitsDto(
		businessUnitsDto: BusinessUnitStructDto[]
	): BusinessUnitLookup[] {
		return businessUnitsDto.map((bu) => new BusinessUnitLookup(bu.id, bu.nm));
	}

	private convertServiceChannelsDto(
		channelsDto: ServiceChannelStructDto[],
		prototypesDto: ServiceChannelPrototypeStructDto[]
	): ServiceChannelLookup[] {
		return channelsDto.map((ch) => {
			const proto = prototypesDto.find((p) => p.id === ch.p);
			const channel = new ServiceChannelLookup(ch.id, proto.nm);
			channel.businessUnitId = ch.bu;
			return channel;
		});
	}

	private getValueFromConfig(configString: string, key: string, defaultValue: number): number {
		if (!configString) return defaultValue;
		let parts = configString.split(',');
		if (parts.length === 0) return defaultValue;
		let part = parts.find((x) => x.startsWith(`${key}=`));
		if (!part) return defaultValue;
		parts = part.split('=');
		if (parts.length === 2) return parseInt(parts[1]);
		return defaultValue;
	}

	private getMaximumRows(config: string, defaultRows: number): number {
		return this.getValueFromConfig(config, 'R', defaultRows);
	}

	private getMaximumTextLength(config: string, defaultTextLength: number): number {
		return this.getValueFromConfig(config, 'L', defaultTextLength);
	}

	private createTemplateFieldFromDto = (template: TemplateStructDto) => {
		const textDateType = 1;
		const locationDataType = 2;
		const templateField = new TemplateField(template.id, template.l);
		templateField.fieldId = template.f;
		templateField.ordinal = template.o;
		templateField.isRequired = template.r;
		templateField.label = template.l;
		templateField.templateId = template.id;
		templateField.lookupId = template.lu;
		templateField.datatype = template.t;
		if (template.t === locationDataType) templateField.fieldType = TemplateFieldType.Location;
		else if (template.t === textDateType)
			templateField.fieldType = !!templateField.lookupId
				? TemplateFieldType.TextWithLookup
				: TemplateFieldType.TextOnly;
		if (!!template.c) {
			const maxRows = this.getMaximumRows(template.c, 1);
			const maxLength = this.getMaximumTextLength(template.c, 50);
			templateField.maxCharacterLength = maxLength;
			templateField.numberOfRows = maxRows;
		}
		return templateField;
	};

	private getHumanizedTimeRemaining(time: number): string {
		const timeRemaining = formatDistance(0, time * 60000);
		if (timeRemaining.startsWith('about')) return timeRemaining.slice(6);
		return timeRemaining;
	}

	private convertServicesDto(
		servicesDto: ServiceStructDto[],
		prototypesDto: ServicePrototypeStructDto[],
		templates: TemplateStructDto[]
	): ServiceLookup[] {
		return servicesDto.map((svc) => {
			const proto = prototypesDto.find((p) => p.id === svc.p);
			const service = new ServiceLookup(svc.id, proto.nm);
			service.serviceChannelId = svc.ch;
			service.description = proto.de;
			service.gpsMode = proto.g;
			service.timer1 = proto.t1;
			service.timer2 = proto.t2;
			service.responseTime = this.getHumanizedTimeRemaining(proto.t1);
			service.allowedCompletionTime = this.getHumanizedTimeRemaining(proto.t2);
			if (proto.tid) {
				const extractedTemplates = templates
					.filter((template) => template.id === proto.tid)
					.sort((a, b) => a.o - b.o);
				console.log('Extracted Templates:', extractedTemplates);
				const allTemplates = extractedTemplates.map(this.createTemplateFieldFromDto);
				console.log('Converted Templates', allTemplates);
				service.template = { templateId: proto.tid, templateFields: allTemplates };
			}
			return service;
		});
	}

	private convertServiceStructureBundleDto(dto: ServiceStructBundleDto): ServiceStructureLookup {
		const lookup = new ServiceStructureLookup();
		lookup.businessUnits = this.convertBusinessUnitsDto(dto.businessUnits);
		lookup.channels = this.convertServiceChannelsDto(dto.channels, dto.channelPrototypes);
		lookup.services = this.convertServicesDto(
			dto.services,
			dto.servicePrototypes,
			dto.templates
		);
		return lookup;
	}
}
