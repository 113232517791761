import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaskChangeStoreService {
	private _changesStore = new Set<number>();
	private _changesStore$ = new Subject<Set<number>>();
	private _numberOfChanges$ = new BehaviorSubject(0);

	get changesInStore$() {
		return this._changesStore$;
	}

	get numberOfChanges$() {
		return this._numberOfChanges$;
	}

	addKeyToChangeSet(key: number): void {
		if (this.isKeyInChangeset(key)) {
			return;
		}
		this._changesStore.add(key);
		this._changesStore$.next(this._changesStore);
		const numberOfChanges = this._changesStore.size;
		this._numberOfChanges$.next(numberOfChanges);
	}

	removeKeyFromChangeset(key: number): void {
		if (!this.isKeyInChangeset(key)) {
			return;
		}
		this._changesStore.delete(key);
		const numberOfChanges = this._changesStore.size;
		this._numberOfChanges$.next(numberOfChanges);
	}

	isKeyInChangeset(key: number): boolean {
		return this._changesStore.has(key);
	}

	// The countChanges() function expects an array of protask ids.  It goes through each
	// protask id and counts the number of protasks from that list that are also contained
	// in the changeset.  This number is then displayed on the UI for submitted and
	// received protask counts.
	countChanges(keys: number[]): number {
		return keys.reduce((accumulator, key) => accumulator + (this._changesStore.has(key) ? 1 : 0), 0);
	}

	reset(): void {
		this._changesStore.clear();
		this.numberOfChanges$.next(0);
	}

	getNumberOfChanges(): number {
		return this._changesStore.size;
	}

	hasChanges(): boolean {
		if (this._changesStore.size === 0) {
			return false;
		}
		return true;
	}
}
