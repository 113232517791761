import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CreateProtaskComponent } from './protasks/create-protask/create-protask.component';
import { ServiceStructureResolver } from './protasks/protask.resolvers';
import { MsalGuard } from '@azure/msal-angular';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [MsalGuard],
		data: { title: 'Home' },
	},
	{
		path: 'create',
		component: CreateProtaskComponent,
		canActivate: [MsalGuard],
		resolve: { serviceStructure: ServiceStructureResolver },
		data: { title: 'Create Task' },
	},
	{
		path: 'settings',
		component: SettingsComponent,
		canActivate: [MsalGuard],
	},
	{
		path: 'views',
		canLoad: [MsalGuard],
		loadChildren: () => import('./views/views.module').then((m) => m.ViewsModule),
	},
	{
		path: 'tasks',
		canLoad: [MsalGuard],
		data: { title: 'Tasks' },
		loadChildren: () => import('./protasks/protasks.module').then((m) => m.ProtasksModule),
	},
	{
		path: 'reports',
		canLoad: [MsalGuard],
		data: { title: 'Reports' },
		loadChildren: () => import('./reports/report-catalogue.module').then((m) => m.ReportsModule),
	},
	{
		path: 'help',
		canLoad: [MsalGuard],
		data: { title: 'Help' },
		loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
	},
	{
		path: 'news',
		canLoad: [MsalGuard],
		data: { title: 'News' },
		loadChildren: () => import('./news/news.module').then((m) => m.NewsModule),
	},
	{ path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
	providers: [ServiceStructureResolver],
})
export class AppRoutingModule {}
