export enum ProtaskState {
  None = 0,
  Open = 1,
  InProgress = 2,
  Complete = 3,
  Closed = 4,
  CancelPending = 5,
  Canceled = 6
}

export enum PushMessagePayloadType {
  None = 0,
  ProtaskDetail = 1,
  Comment = 2,
  StateChange = 3
}

export enum TemplateFieldType {
	None,
	TextOnly,
	Location,
	TextWithLookup
}

// [Flags]
export enum ProtaskOperation
{
  None = 0,
  CreateProtask = 1,
  ClaimOpenProtask = 2,
  CompleteInProgressProtask = 4,
  CloseCompletedProtask = 8,
  CancelOpenProtask = 16,
  UncancelProtask = 32,
  CancelInProgressProtask = 64,
  AddComment = 128,
  ResubmitCompletedProtask = 256,

  // When a protask is both created and assigned to a provider in the in-progress state.
  // Configured at the service level or by an auto-task.
  InstantClaim = 512,

  // When a protask is transitioned from in-progress to canceled.  Must be configured at service level.
  InstantCancelInProgressProtask = 1024,

  // When a protask is transitioned from complete to closed.  Must be configured at service level
  InstantCloseInProgressProtask = 2048,

  // When a protask is in the cancel-pending state and it's moved to the canceled state
  AcknowledgeCancelPending = 4096,
  AddCommentToOpenProtask = 8192,
  StandardWatch = 12287 // 0x1 | 0x2 | 0x4 | 0x8 | 0x10 | 0x20 | 0x40 | 0x80 | 0x100 | 0x200 | 0x400 | 0x800 | 8192
}

export enum DataSyncAction {
  None = 0,
  Set = 1,
  Clear = 2,
  SetAndNotify = 3,
  ClearAndNotify = 4,
  SetDueToCollaboration = 5,
  ClearDueToCollaboration = 6,
  SetAndNotifyDueToCollaboration = 7,
  SetDueToCollaborationButClearIfCollaborationNotSupported = 8,
  SetAndNotifyDueToCollaborationButClearIfCollaborationNotSupported = 9
}


// [Flags]
export enum ProtaskCollaboration {
    None = 0,
    Submitter = 1,
    Provider = 2,
    SubmitterAndProvider = 3,
    SubmitterCategory1 = 4,
    SubmitterCategory2 = 8,
    SubmitterCategory3 = 16,
    ProviderCategory1 = 32,
    ProviderCategory2 = 64,
    ProviderCategory3 = 128
}

export enum ProtaskUserRole
{
    None = 0,
    Submitter = 1,
    Provider = 2,
    ServiceChannelProviders = 3,
    ServiceChannelSubmitters = 4,
    Robot = 5
}

export enum GeoLocationGpsFixStatus {
  None = 0,
  Ok = 1,
  UnableToAquireFix = 2,
  NotSupported = 3,
  NotEnabled = 4,
  PermissionDenied = 5,
  Timeout = 6,
  Fallback = 7,
  UserDeclinedToEnableLocationServices = 8,
  WebApp = 9,
  AddressOnly = 10
}

export enum TaskStatusFilters {
	Open = 'open',
	InProgress = 'in progress',
	OpenAndInProgress = 'both'
}

export enum TaskCondition {
	Okay = 0,
	Warning = 1,
	Danger = 2
}

export enum ProtaskMode {
	Submitted,
	Received,
	Report
}

export enum OperationOutcome {
	NoChange,
	Updated,
	Inserted,
	Deleted
}

export enum AgeComparison {
	Same,
	Older,
	Newer
}
