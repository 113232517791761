import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as signalR from '@microsoft/signalr';
import { StashService } from 'src/app/utilities/services/stash.service';
import { environment } from 'src/environments/environment';
import { PushMessagePayloadType } from '../../models/enumerations';
import { IPushModel } from '../../models/push.model';
import { ReceiveNewCommentService } from './receive-new-comment.service';
import { ReceiveNewStateChangeService } from './receive-new-state-change.service';
import { ReceiveNewTaskService } from './receive-new-task.service';

@Injectable({ providedIn: 'root' })
export class SignalRService {
	private _hubConnection: signalR.HubConnection | undefined;
	private readonly _signalRServerUrl = environment.signalrConnectionString;

	constructor(
		private _msalService: MsalService,
		private _stashService: StashService,
		private _newCommentService: ReceiveNewCommentService,
		private _newStateChangeService: ReceiveNewStateChangeService,
		private _newTaskService: ReceiveNewTaskService
	) {}

	startSignalRConnection() {
		this._hubConnection = this.getNewHubConnectionBuild();
		this._hubConnection
			.start()
			.then(() => {
				console.log('Signalr connection established');
				this.listenForSignalrUpdates();
			})
			.catch((err) => console.log(`SignalR connection error ${err}`));
	}

	stopSignalRConnection() {
		this._hubConnection.stop().then(() => console.log('Stopped connection to Signalr'));
	}

	private getNewHubConnectionBuild() {
		const activeAccount = this._msalService.instance.getActiveAccount();
		const oidToken = activeAccount?.idTokenClaims.oid;
		const idToken = this._stashService.getIdToken();
		return new signalR.HubConnectionBuilder()
			.withUrl(this._signalRServerUrl, {
				headers: { 'x-ms-client-principal-id': oidToken },
				accessTokenFactory: () => idToken,
			})
			.withAutomaticReconnect()
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	private listenForSignalrUpdates() {
		this._hubConnection?.on('updateMessage', (signalrDto) => this.handleSignalrUpdate(signalrDto));
	}

	private handleSignalrUpdate(signalrDto: IPushModel) {
		console.log('Handling update', signalrDto);
		switch (signalrDto.payloadType) {
			case PushMessagePayloadType.ProtaskDetail:
				this._newTaskService.processPushModel(signalrDto);
				break;
			case PushMessagePayloadType.StateChange:
				this._newStateChangeService.processPushModel(signalrDto);
				break;
			case PushMessagePayloadType.Comment:
				this._newCommentService.processPushModel(signalrDto);
				break;
			default:
				break;
		}
	}
}
