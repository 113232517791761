<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
	<div>Latitude and Longitude</div>
	<button mat-icon-button aria-label="Close" (click)="onCancelClick()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<p>
		Latitude and longitude are optional but recommended. The easiest way to get these values is
		by
		finding your desired location
		using Google Maps. You can search for the address or zoom and pan to find the precise
		location
		of interest.
	</p>
	<p>
		Click the map with your mouse at the desired position. A small window will appear at the
		bottom
		of
		your screen with a an address or city name along with the latitude and longitude in small
		grey
		text below it.
	</p>
	<p>
		Click on the grey latitude and longitude to open a larger window on your screen with the
		latitude and
		longitude displayed at the very top, e.g. 43.160024, -80.764373
	</p>
	<p>
		Copy these two values together (comma included) from Google Maps and then come back to this
		screen and
		paste the coordinates into the text box below.
	</p>
	<div fxLayout="row" fxLayoutGap="12px">
		<div>
			<a fxFlexAlign="start" mat-raised-button color="primary" href="https://maps.google.com/"
				target="_blank">Open Google Maps</a>
		</div>
		<form [formGroup]="latLngForm" fxFlex="grow" fxLayout="column" fxLayoutGap="12px">
			<mat-form-field>
				<mat-label>Latitude and Longitude (separated by a comma)</mat-label>
				<input matInput type="text" inputmode="text" formControlName="latLngString"
					placeholder="Paste the latitude and longitude here">
			</mat-form-field>
		</form>
	</div>
	<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="color:red">{{ reason }}</div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
	<button mat-button (click)="onCancelClick()">Cancel</button>
	<button mat-button (click)="onOkClick()" cdkFocusInitial>OK</button>
</div>