import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/shared/news-center/models/news.models';

@Component({
	selector: 'app-news-card',
	templateUrl: './news-card.component.html',
	styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent {
	@Input() title: string;
	@Input() urlExtension: string;
	@Input() articles: Article[] = [];
	@Input() showLoading: boolean;

	constructor(private _router: Router) {}

	navigateToNews() {
		this._router.navigateByUrl(`news/${this.urlExtension}`);
	}

	navigateToArticle(preview: Article) {
		if (preview.ct === 1 || preview.ct === 3) {
			this._router.navigateByUrl(`news/${this.urlExtension}/${preview.id}`);
		}
	}
}
