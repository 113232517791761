<section
	[ngClass]="isFileBeingDraggedOver ? 'solid-border' : 'dashed-border'"
	style="background-color: #f1f1f1"
	(drop)="dropHandler($event)"
	(dragover)="dragOverHandler($event)"
	(dragleave)="dragLeaveHandler()"
>
	<div (click)="openFileExplorer()" class="pointer">
		<div [ngClass]="isAFileAttached ? 'smaller-file-attachment caption' : 'file-attachment body-1'">
			<mat-icon class="text-dark-2">attach_file</mat-icon>
			<span>Attach Files</span>
			<div>
				<span appHighlight [defaultColour]="'#21212199'" [highlightColour]="'black'">
					Click to browse your PC or Drop files here
				</span>
				<input type="file" id="hiddenInput" hidden multiple (change)="onFileAddedFromExplorer($event)" />
			</div>
		</div>
	</div>

	<div [ngClass]="{ 'image-row': !!fileListForm.length }">
		<div *ngFor="let control of fileListForm.controls">
			<app-image-preview
				[attachedFile]="control.value"
				(deleteFileEvent)="deleteFile($event)"
			></app-image-preview>
		</div>
	</div>
</section>
