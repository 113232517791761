import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ImagePreviewFileUploaderComponent } from './image-preview-file-uploader/image-preview-file-uploader.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [
		ImagePreviewFileUploaderComponent,
		SidenavMenuComponent,
	],
	imports: [
		ReactiveFormsModule,
		CommonModule,
		SharedModule,
		RouterModule,
		MaterialModule,
		MatCardModule,
		MatIconModule,
		MatListModule,
		MatBadgeModule,
		MatTooltipModule,
		MatButtonModule,
		MatToolbarModule,
	],
	exports: [
		ImagePreviewFileUploaderComponent,
		SidenavMenuComponent,
	],
})
export class CoreModule {}
