import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { HttpRequestService } from './utilities/services/http-request.service';
import { GenericApiError } from './core/models/generic-api-error.model';

@Injectable({
	providedIn: 'root',
})
export class HandleHttpErrorService {
	constructor(private _msalService: MsalService, private _httpRequestService: HttpRequestService) {}

	handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		return (src: Observable<any>) =>
			src.pipe(
				catchError((error) => {
					if (error.status === 401) {
						if (this.tokenHasExpired(error.error)) {
							return this._msalService.acquireTokenSilent({ scopes: [] }).pipe(
								switchMap((responseRefreshToken) => {
									return next.handle(
										this._httpRequestService.setAuthorizationHeader(
											request,
											responseRefreshToken.idToken
										)
									);
								})
							);
						}
					}
					return throwError(error);
				})
			);
	}

	private tokenHasExpired(error: GenericApiError): boolean {
		return error?.message.toLowerCase().includes(TokenValidationCode.tokenExpired.toLocaleLowerCase());
	}
}

export enum TokenValidationCode {
	tokenExpired = 'IDX10223',
	invalidSignature = 'IDX10511',
}
