<form [formGroupName]="locationFormName">
	<section class="flex-column">
		<span class="subtitle-1 title-spacing">Location</span>

		<!-- Street Address -->
		<mat-form-field class="full-width" appearance="outline">
			<mat-label>Add Street Address</mat-label>
			<input matInput type="text" formControlName="address" />
			<mat-error
				*ngIf="locationForm.controls['address'].hasError('required')"
			>
				Address is <strong>required</strong>
			</mat-error>
		</mat-form-field>

		<!-- City / Region -->
		<div class="flex-row" style="margin-bottom: 32px">
			<!-- City -->
			<div class="col">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Add City</mat-label>
					<input matInput type="text" formControlName="city" />
					<mat-error
						*ngIf="
							locationForm.controls['city'].hasError('required')
						"
					>
						City is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Region -->
			<div class="col">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Select State / Province</mat-label>
					<mat-select formControlName="region">
						<mat-optgroup
							*ngFor="let regionGroup of regionOptions"
							[label]="regionGroup.name"
						>
							<mat-option
								*ngFor="let region of regionGroup.locations"
								[value]="region.abbreviation"
							>
								{{ region.name }}
							</mat-option>
						</mat-optgroup>
					</mat-select>
					<mat-error
						*ngIf="
							locationForm.controls['region'].hasError('required')
						"
					>
						Region is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<!-- Latitude / Longitude -->
		<span class="subtitle-1 title-spacing">Latitude & Longitude</span>
		<div class="flex-row" style="margin-bottom: 16px">
			<!-- Latitude -->
			<div class="col">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Add Latitude</mat-label>
					<input matInput type="number" formControlName="latitude" />
					<mat-error
						*ngIf="
							locationForm.controls['latitude'].hasError(
								'required'
							)
						"
					>
						Latitude is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Longitude -->
			<div class="col">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Add Longitude</mat-label>
					<input matInput type="number" formControlName="longitude" />
					<mat-error
						*ngIf="
							locationForm.controls['longitude'].hasError(
								'required'
							)
						"
					>
						Longitude is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<button
			type="button"
			class="button full-width"
			mat-button
			(click)="openLocationDialog()"
		>
			<span style="padding-right: 8px">Find latitude and longitude </span>
			<mat-icon> my_location </mat-icon>
		</button>
	</section>
</form>
