import { Injectable } from '@angular/core';
import { Protask } from '../../models/domain.models';
import { IProtaskDetail2Dto } from '../../models/dto.models';
import { DataSyncAction, OperationOutcome, ProtaskOperation } from '../../models/enumerations';
import { IPushModelProcessor, IPushModel } from '../../models/push.model';
import { TaskStoreService } from '../../../protasks/services/task-store.service';
import { HelperService } from '../../../utilities/services/helper.service';
import { DomainModelFactoryService } from '../domain-model-factory.service';
import { SnackbarService } from '../snackbar.service';
import { TaskChangeStoreService } from './task-change-store.service';

@Injectable({ providedIn: 'root' })
export class ReceiveNewTaskService implements IPushModelProcessor {
	constructor(
		private _taskStoreService: TaskStoreService,
		private _taskBuilderService: DomainModelFactoryService,
		private _snackbarService: SnackbarService,
		private _taskChangeStore: TaskChangeStoreService,
		private _helperService: HelperService
	) {}

	processPushModel(pushModel: IPushModel): void {
		const protask = this.mapDtoToProtask(pushModel.payload);
		const dataOperationOutcome = this.performDatabaseManagment(protask);

		if (this.isNotificationNeeded(pushModel.action, dataOperationOutcome)) {
			this._taskChangeStore.addKeyToChangeSet(protask.id);
			this.notifyUser(protask, pushModel.operation);
		}
	}

	private isNotificationNeeded(
		action: DataSyncAction,
		dataOperationOutcome: OperationOutcome
	): boolean {
		if (dataOperationOutcome !== OperationOutcome.NoChange) {
			return this._helperService.shouldNotifyUser(action);
		}
		return false;
	}

	private mapDtoToProtask(protaskDto: IProtaskDetail2Dto): Protask {
		return this._taskBuilderService.buildProtask(protaskDto);
	}

	private performDatabaseManagment(protask: Protask): OperationOutcome {
		return this._taskStoreService.upsertTask(protask);
	}

	private notifyUser(newTask: Protask, protaskOperation: ProtaskOperation): void {
		this._snackbarService.notifyOnTaskUpdate(newTask, protaskOperation);
	}
}
