import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';

import { ChartCardComponent } from './chart-card/chart-card.component';
import { NewsCardComponent } from './news-card/news-card.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { HighlightDirective } from './directives/highlight.directive';
import { ImagePreviewComponent } from './file-uploader/image-preview/image-preview.component';
import { TextTemplateComponent } from './text-template/text-template.component';
import { LookupTemplateComponent } from './lookup-template/lookup-template.component';
import { LocationTemplateComponent } from './location-template/location-template.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { ConditionDirective } from './directives/condition.directive';
import { StatusChipColorDirective } from './directives/status-chip-color.directive';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfoCardComponent } from './info-card/info-card.component';
import { DateRangeSelectorComponent } from './date-range-selector/date-range-selector.component';
import { ProtaskInfoComponent } from './protask-info/protask-info.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { StateTransitionChipComponent } from './state-transition-chip/state-transition-chip.component';
import { DetailsChipListComponent } from './details-chip-list/details-chip-list.component';
import { DetailsMenuButtonComponent } from './details-menu-button/details-menu-button.component';
import { GenericToolbarComponent } from './generic-toolbar/generic-toolbar.component';
import { DynamicFilterDrawerComponent } from './generic-toolbar/dynamic-filter/dynamic-filter-drawer/dynamic-filter-drawer.component';
import { DynamicFilterComponent } from './generic-toolbar/dynamic-filter/dynamic-filter.component';
import { DynamicFormComponent } from './generic-toolbar/dynamic-filter/dynamic-form/dynamic-form.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaterialModule } from '../core/material.module';
import { TaskTableComponent } from './task-table/task-table.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FilterColumnComponent } from './filter-column/filter-column.component';
import { UserProfilePictureComponent } from './user-profile-picture/user-profile-picture.component';
import { BgRandomColorDirective } from './directives/bg-random-color.directive';
import { ChangesIndicatorComponent } from './changes-indicator/changes-indicator.component';
import { NewsCenterComponent } from './news-center/news-center.component';
import { UseCasesComponent } from './news-center/use-cases/use-cases.component';
import { UseCaseCardComponent } from './news-center/use-cases/use-case-card/use-case-card.component';
import { KnowledgeBaseComponent } from './news-center/knowledge-base/knowledge-base.component';
import { HomeDetailComponent } from './news-center/home-detail/home-detail.component';
import { HomeDetailCardComponent } from './news-center/home-detail/home-detail-card/home-detail-card.component';
import { NewsDetailsComponent } from './news-center/news-details/news-details.component';
import { KnowledgeBaseCardComponent } from './news-center/knowledge-base/knowledge-base-card/knowledge-base-card.component';
import { SanitizerPipe } from './news-center/news-details/sanitizer.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TaskChangeDirective } from './directives/task-change.directive';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		MaterialModule,
		MatCardModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatInputModule,
		MatSelectModule,
		MatDialogModule,
		MatTooltipModule,
		MatGridListModule,
		OverlayModule,
		TextFieldModule,
		MatChipsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMenuModule,
		MatToolbarModule,
		MatButtonModule,
		MatSortModule,
		MatTableModule,
		MatProgressSpinnerModule,
	],
	exports: [
		NewsCardComponent,
		ChartCardComponent,
		FileUploaderComponent,
		HighlightDirective,
		ImagePreviewComponent,
		TextTemplateComponent,
		LookupTemplateComponent,
		LocationTemplateComponent,
		ConditionDirective,
		StatusChipColorDirective,
		AttachmentPreviewComponent,
		BreadcrumbComponent,
		InfoCardComponent,
		DateRangeSelectorComponent,
		ProtaskInfoComponent,
		StateTransitionChipComponent,
		DetailsChipListComponent,
		DetailsMenuButtonComponent,
		GenericToolbarComponent,
		DynamicFilterComponent,
		DynamicFormComponent,
		DynamicFilterDrawerComponent,
		TaskTableComponent,
		FilterColumnComponent,
		UserProfilePictureComponent,
		ChangesIndicatorComponent,
		NewsCenterComponent,
		UseCasesComponent,
		TaskChangeDirective,
	],
	declarations: [
		NewsCardComponent,
		ChartCardComponent,
		FileUploaderComponent,
		HighlightDirective,
		ImagePreviewComponent,
		TextTemplateComponent,
		LookupTemplateComponent,
		LocationTemplateComponent,
		ImageDialogComponent,
		ConditionDirective,
		StatusChipColorDirective,
		BgRandomColorDirective,
		AttachmentPreviewComponent,
		BreadcrumbComponent,
		InfoCardComponent,
		DateRangeSelectorComponent,
		ProtaskInfoComponent,
		StateTransitionChipComponent,
		DetailsChipListComponent,
		DetailsMenuButtonComponent,
		GenericToolbarComponent,
		DynamicFilterComponent,
		DynamicFormComponent,
		DynamicFilterDrawerComponent,
		TaskTableComponent,
		FilterColumnComponent,
		UserProfilePictureComponent,
		ChangesIndicatorComponent,
		NewsCenterComponent,
		UseCasesComponent,
		UseCaseCardComponent,
		KnowledgeBaseComponent,
		HomeDetailComponent,
		HomeDetailCardComponent,
		NewsDetailsComponent,
		KnowledgeBaseCardComponent,
		SanitizerPipe,
		TaskChangeDirective,
	],
	providers: [],
})
export class SharedModule {}
