import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';

@Directive({ selector: '[appHighlight]' })
export class HighlightDirective implements OnInit {
	@Input() defaultColour: string;
	@Input() highlightColour: string;
	@HostBinding('style.color') fontColour: string;

	ngOnInit(): void {
		this.fontColour = this.defaultColour;
	}

	@HostListener('mouseenter')
	mouseOver() {
		this.fontColour = this.highlightColour;
	}

	@HostListener('mouseleave')
	mouseLeave() {
		this.fontColour = this.defaultColour;
	}
}
