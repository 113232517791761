export enum DateRangeTypes {
	Today = 'today',
	Yesterday = 'yesterday',
	Week = 'week',
	Month = 'month',
	Quarter = 'quarter',
	Year = 'year',
	Custom = 'custom',
}

export enum TimeZoneTypes {
	UTC,
	AlaskanStandardTime,
	PacificStandardTime,
	USMountainTime,
	MountainStandardTime,
	CentralStandardTime,
	CanadaCentralStandardTime,
	EasternStandardTime,
	AtlanticStandardTime,
	NewfoundlandStandardTime,
}
