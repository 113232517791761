import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ProfileService } from './core/services/profile/profile.service';
import { LoadingService } from './utilities/services/loading.service';
import { StashService } from './utilities/services/stash.service';
import { ProfileHttpService } from './core/services/profile/profile-http.service';
import { RedirectEventService } from './redirect-event.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	name: string;
	profilePicUrl: any;
	isSidenavOpen = true;
	absoluteOrRelativePosition: string;
	isLoading$: BehaviorSubject<boolean>;
	activeAccount$: BehaviorSubject<AccountInfo | null>;

	private _subSink = new SubSink();
	private _wasGraphEndpointCalled = false;

	constructor(
		private _msalService: MsalService,
		private _stashService: StashService,
		private _profileService: ProfileService,
		private _loadingService: LoadingService,
		private _profileHttpService: ProfileHttpService,
		private _redirectEventService: RedirectEventService,
		private _msalBroadcastService: MsalBroadcastService
	) {}

	ngOnInit() {
		this.activeAccount$ = this._profileService.msalAccount$;
		this.isLoading$ = this._loadingService.isLoading$;
		this.observeRedirectEvents();
		this.observeCompleteInteraction();
		this.observeHeightSize();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	toggleSidenav() {
		this.isSidenavOpen = !this.isSidenavOpen;
	}

	logout(): void {
		this._msalService.logoutRedirect();
	}

	private observeRedirectEvents() {
		this._subSink.sink = this._msalBroadcastService.msalSubject$.subscribe((data) => this.handleEvent(data));
	}

	private observeCompleteInteraction() {
		this._subSink.sink = this._msalBroadcastService.inProgress$
			.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
			.subscribe(() => this.handleInteraction());
	}

	@HostListener('window:resize')
	private observeHeightSize() {
		if (window.innerHeight <= 943) {
			this.absoluteOrRelativePosition = 'bottom-relative';
		} else {
			this.absoluteOrRelativePosition = 'bottom-absolute';
		}
	}

	private handleEvent(event: EventMessage) {
		switch (event.eventType) {
			case EventType.INITIALIZE_START:
				this._redirectEventService.handleInitializationStart();
				break;
			case EventType.LOGIN_SUCCESS:
				this._redirectEventService.handleLoginSuccess();
				break;
			case EventType.LOGOUT_START:
				this._redirectEventService.handleLogoutStart();
				break;
			case EventType.ACQUIRE_TOKEN_SUCCESS:
				this._redirectEventService.handleAquireTokenSuccess(event.payload);
				this.name = event.payload['idTokenClaims']['name'];
				if (!this.profilePicUrl && !this._wasGraphEndpointCalled) {
					this.acquireProfilePicUrl();
					this._wasGraphEndpointCalled = true;
				}
				break;
			default:
				break;
		}
	}

	private handleInteraction() {
		this._redirectEventService.handleCompleteInteraction();
	}

	private async acquireProfilePicUrl() {
		const signedInUserOid = this._stashService.getOid();
		this.profilePicUrl = await this._profileHttpService.callUsersProfilePicture(signedInUserOid);
	}
}
