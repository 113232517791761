import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormBuilder,
	FormControl,
	FormGroup,
	FormGroupDirective,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { SubSink } from 'subsink';

// Models
import { TemplateField } from 'src/app/core/models/domain.models';

// Services
import { TemplateFormService } from 'src/app/shared/template-form.service';
import { TemplateType } from '../../protasks/create-protask/models/create-task.types';

@Component({
	selector: 'app-text-template',
	templateUrl: './text-template.component.html',
	styleUrls: ['./text-template.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TextTemplateComponent implements OnInit, OnDestroy {
	@Input() textTemplate: TemplateField;

	textControlName: string;

	private _parentForm: FormGroup;
	private _subSink = new SubSink();

	constructor(
		private _fb: FormBuilder,
		private _parentFormDirective: FormGroupDirective,
		private _templateFormService: TemplateFormService
	) {}

	ngOnInit(): void {
		this.setupTextControl();
		this.setValidatorsOnForm();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
		this._parentForm.removeControl(this.textControlName);
	}

	get textControl(): FormControl {
		return this._parentForm.controls[this.textControlName] as FormControl;
	}

	private setupTextControl() {
		this.textControlName = this._templateFormService.generateTemplateFormName(
			TemplateType.Text,
			this.textTemplate.fieldId
		);
		this._parentForm = this._parentFormDirective.form;
		this._parentForm.addControl(this.textControlName, this._fb.control(null));
	}

	private setValidatorsOnForm(): void {
		const validatorFunctions: ValidatorFn[] = [];
		if (!!this.textTemplate.isRequired) {
			validatorFunctions.push(Validators.required);
		}
		if (!!this.textTemplate.maxCharacterLength) {
			const maxCharacterLength = this.textTemplate.maxCharacterLength;
			validatorFunctions.push(Validators.maxLength(maxCharacterLength));
		}
		this.textControl.setValidators(validatorFunctions);
	}
}
