import { Injectable } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns';
import { ITaskTime, Protask, ProtaskMessage } from '../models/domain.models';
import { IProtaskDetail2Dto, IProtaskMessageDto } from '../models/dto.models';
import { HelperService } from '../../utilities/services/helper.service';
import { ProtaskSlaComplianceService } from 'src/app/protasks/services/protask-compliance.service';

@Injectable({ providedIn: 'root' })
export class DomainModelFactoryService {
	constructor(private helperService: HelperService, private _slaService: ProtaskSlaComplianceService) {}

	buildProtask(protaskDto: IProtaskDetail2Dto): Protask {
		const protask = new Protask();
		protask.id = protaskDto.id;
		protask.state = protaskDto.st;
		protask.stateText = this.helperService.stateToText(protaskDto.st);
		protask.openedAt = new Date(protaskDto.to);
		protask.respondedAt = protaskDto.tr ? new Date(protaskDto.tr) : null;
		protask.submitter = this.helperService.formatUserName(protaskDto.sfn, protaskDto.sln);
		protask.submitterHash = protaskDto.sh;
		protask.slaMinutesToRespond = protaskDto.s1;
		protask.slaMinutesToComplete = protaskDto.s2;
		protask.service = protaskDto.sv;
		protask.channel = protaskDto.ch;
		protask.providerBusinessUnit = protaskDto.pbun;
		protask.submitterBusinessUnit = protaskDto.sbun;
		protask.provider = this.helperService.formatUserName(protaskDto.pfn, protaskDto.pln, 'Unclaimed');
		protask.updatedAt = new Date(protaskDto.lt);
		protask.isWatched = protaskDto.w;
		protask.userRole = protaskDto.ur;
		protask.lastTouched = new Date(protaskDto.lt);
		protask.collabPermissions = protaskDto.co;
		protask.reference = !!protaskDto.r ? protaskDto.r : null;
		protask.templateFields = !!protaskDto.tflds ? protaskDto.tflds : null;
		protask.locationFields = !!protaskDto.aflds ? protaskDto.aflds : null;
		protask.shouldBlink = false;
		protask.expiry = this.getExpiryDate(protask);
		protask.condition = this.getTaskCondition(protask);
		protask.isInTaskChangeStore = false;
		return protask;
	}

	buildProtaskMessage(dto: IProtaskMessageDto): ProtaskMessage {
		const message = new ProtaskMessage();
		message.id = dto.id;
		message.protaskId = dto.pid;
		message.time = new Date(dto.t);
		message.humanizedTime = formatDistanceToNowStrict(new Date(dto.t), { addSuffix: true });
		message.text = dto.txt;
		message.fileName = dto.f;
		message.contentType = dto.c;
		message.url = dto.u;
		message.author = this.helperService.formatUserName(dto.fn, dto.ln);
		message.azureUserId = dto.apu?.toUpperCase();
		return message;
	}

	private getExpiryDate(task: ITaskTime) {
		const sla = this._slaService.getProtaskSlaCompliance(task);
		return !!sla?.expiryTime ? sla.expiryTime : new Date();
	}

	private getTaskCondition(task: ITaskTime) {
		const sla = this._slaService.getProtaskSlaCompliance(task);
		return sla?.condition;
	}
}
