import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { GridLayout } from '../core/types/grid-layout.type';
import { ViewportService } from '../utilities/layout/viewport.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
	gridLayoutSizes: GridLayout;
	private _subSink = new SubSink();

	constructor(private _viewportService: ViewportService) {}

	ngOnInit(): void {
		this.observeViewport();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	private observeViewport() {
		this._subSink.sink = this._viewportService.observeViewportSize().subscribe((screenSize) => {
			console.log(screenSize);
			this.gridLayoutSizes = screenSize;
		});
	}
}
