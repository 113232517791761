export enum TemplateType {
	Text = 'text',
	Location = 'location',
	Lookup = 'lookup',
}

export enum LocationFormProperties {
	Address = 'address',
	City = 'city',
	Latitude = 'latitude',
	Longitude = 'longitude',
	Region = 'region',
}

export enum LocationFormPropertyAbbreviations {
	Address = 'ad',
	City = 'ci',
	Latitude = 'l',
	Longitude = 'lo',
	Region = 'sp',
}
