import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Protask } from 'src/app/core/models/domain.models';
import { OperationOutcome, AgeComparison, DataSyncAction } from 'src/app/core/models/enumerations';

@Injectable({ providedIn: 'root' })
export class TaskStoreService {
	private _activeTasks = new BehaviorSubject<any>([]);

	get activeTasks(): Protask[] {
		return this._activeTasks.getValue();
	}

	get activeTasks$(): Observable<Protask[]> {
		return this._activeTasks;
	}

	addTask(task: Protask): void {
		this._activeTasks.next([...this._activeTasks.getValue(), task]);
	}

	addListOfTasks(tasks: Protask[]): void {
		tasks.forEach((task) => this.addTask(task));
	}

	emptyTasksInStore(): void {
		this._activeTasks.next([]);
	}

	updateTask(newTask: Protask): void {
		const currentArray = this._activeTasks.getValue();
		const index = currentArray.findIndex((task) => task.id === newTask.id);
		if (index > -1) {
			currentArray.splice(index, 1, newTask);
			this._activeTasks.next(currentArray);
		}
	}

	removeTask(taskToRemove: Protask): OperationOutcome {
		const currentArray: Protask[] = this._activeTasks.getValue();
		const index = currentArray.findIndex((task) => task.id === taskToRemove.id);
		if (index > -1) {
			currentArray.splice(index, 1);
			this._activeTasks.next(currentArray);
			return OperationOutcome.Deleted;
		}
		return OperationOutcome.NoChange;
	}

	upsertTask(protask: Protask): OperationOutcome {
		const existingTask = this.getTaskById(protask.id);

		if (!existingTask) {
			this.addTask(protask);
			return OperationOutcome.Inserted;
		}

		const ageOfNewProtask = protask.ageComparison(existingTask);

		if (ageOfNewProtask === AgeComparison.Newer) {
			this.updateTask(protask);
			return OperationOutcome.Updated;
		}

		return OperationOutcome.NoChange;
	}

	mergeTaskStateChange(protask: Protask, action: DataSyncAction): OperationOutcome {
		// Tells us if we need to set or clear the task in the local database
		const databaseAction = this.getDatabaseAction(action);

		if (databaseAction === DataSyncAction.Set) {
			return this.upsertTask(protask);
		}

		if (databaseAction === DataSyncAction.Clear) {
			this.upsertTask(protask);
			return this.removeTask(protask);
		}

		return OperationOutcome.NoChange;
	}

	getTaskById$(id: number): Observable<Protask> {
		const filteredTask$ = this._activeTasks.pipe(map((tasks: Protask[]) => tasks.find((task) => task.id === id)));
		return filteredTask$;
	}

	getTaskById(id: number): Protask {
		if (!!this._activeTasks && !!this._activeTasks.getValue() && !!this._activeTasks.getValue().length)
			return this._activeTasks.getValue().find((task) => task.id === id);
		return undefined;
	}

	private getDatabaseAction(action: DataSyncAction): DataSyncAction {
		switch (action) {
			case DataSyncAction.Set:
			case DataSyncAction.SetAndNotify:
			case DataSyncAction.SetAndNotifyDueToCollaboration:
			case DataSyncAction.SetAndNotifyDueToCollaborationButClearIfCollaborationNotSupported:
			case DataSyncAction.SetDueToCollaboration:
			case DataSyncAction.SetDueToCollaborationButClearIfCollaborationNotSupported:
				return DataSyncAction.Set;

			case DataSyncAction.Clear:
			case DataSyncAction.ClearAndNotify:
			case DataSyncAction.ClearDueToCollaboration:
				return DataSyncAction.Clear;

			default:
				return DataSyncAction.None;
		}
	}
}
