import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TaskChangeStoreService } from 'src/app/core/services/signalr/task-change-store.service';
import { LogoutComponent } from 'src/app/utilities/dialogs/logout/logout.component';
import { SubSink } from 'subsink';
import { UserProfile } from '../models/auth.models';
import { ProfileService } from '../services/profile/profile.service';

@Component({
	selector: 'app-sidenav-menu',
	templateUrl: './sidenav-menu.component.html',
	styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements OnInit, OnDestroy {
	@Input() isSidenavOpen!: boolean;
	@Input() currentPageTitle!: string;
	numberOfChanges = 0;
	userProfile$: Observable<UserProfile>;

	private _subSink = new SubSink();

	constructor(
		private _dialog: MatDialog,
		private _profileService: ProfileService,
		private _taskChangeService: TaskChangeStoreService
	) {}

	ngOnInit(): void {
		this.observeTaskChanges();
		this.userProfile$ = this._profileService.myAccessProfile$;
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	logout(): void {
		this._dialog.open(LogoutComponent);
	}

	private observeTaskChanges() {
		this._subSink.sink = this._taskChangeService.numberOfChanges$.subscribe(
			(numberOfChanges) => (this.numberOfChanges = numberOfChanges)
		);
	}
}
