import { Injectable } from '@angular/core';
import { DateRangeTypes } from 'src/app/reports/shared/shared.enums';
import { DateRange } from 'src/app/reports/shared/shared.types';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class StashService {
	setProfilePicture(oid: string, base64: string): void {
		this.setInSessionStorage(`profilePicture${oid.toUpperCase()}`, base64);
	}

	getProfilePicture(oid: string) {
		return this.getFromSessionStorage(`profilePicture${oid.toUpperCase()}`);
	}

	setOid(oid: string): void {
		this.setInSessionStorage(environment.userOid, oid.toUpperCase());
	}

	getOid() {
		return this.getFromSessionStorage(environment.userOid);
	}

	setName(name: string): void {
		this.setInSessionStorage(environment.userName, name);
	}

	getName() {
		return this.getFromSessionStorage(environment.userName);
	}

	setIdToken(token: string): void {
		this.setInSessionStorage(environment.idTokenKey, token);
	}

	getIdToken() {
		return this.getFromSessionStorage(environment.idTokenKey);
	}

	setAccessToken(token: string) {
		this.setInSessionStorage(environment.accessTokenKey, token);
	}

	getAccessToken() {
		return this.getFromSessionStorage(environment.accessTokenKey);
	}

	setTaskRoute(taskRoute: string) {
		this.setInSessionStorage(environment.taskRouteKey, taskRoute.toLowerCase());
	}

	getTaskRoute() {
		return this.getFromSessionStorage(environment.taskRouteKey);
	}

	setDateRange(dateOption: DateRangeTypes, startDate: string = null, endDate: string = null) {
		const dateRange: DateRange = { dateOpt: dateOption };
		if (!!startDate && !!endDate) {
			dateRange.startDate = startDate;
			dateRange.endDate = endDate;
		}
		localStorage.setItem(environment.serviceRatingDateRangeKey, JSON.stringify(dateRange));
	}

	setInLocalStorage(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	getFromLocalStorage(key: string): string {
		return localStorage.getItem(key);
	}

	getObjectFromLocalStorage(key: string): any {
		let parsedObject: any;
		try {
			parsedObject = JSON.parse(localStorage.getItem(key));
		} catch (error) {
			return null;
		}
		return parsedObject;
	}

	setInSessionStorage(key: string, value: string): void {
		sessionStorage.setItem(key, value);
	}

	getFromSessionStorage(key: string): string {
		return sessionStorage.getItem(key);
	}

	getObjectFromSessionStorage(key: string): any {
		let parsedObject: any;
		try {
			parsedObject = JSON.parse(this.getFromSessionStorage(key));
		} catch (error) {
			return null;
		}
		return parsedObject;
	}

	removeFromLocalStorage(key: string): void {
		localStorage.removeItem(key);
	}

	removeFromSessionStorage(key: string): void {
		sessionStorage.removeItem(key);
	}

	getTokens() {
		return {
			idToken: this.getFromSessionStorage(environment.idTokenKey),
			accessToken: this.getAccessToken()
		}
	}
}
