import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
	private _isLoading$ = new BehaviorSubject<boolean>(false);

	get isLoading$() {
		return this._isLoading$;
	}

	setLoadingState(loadingState: boolean) {
		this._isLoading$.next(loadingState);
	}
}
