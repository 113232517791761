import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from 'src/app/core/models/auth.models';
import { StashService } from 'src/app/utilities/services/stash.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService {
	private _msalAccount$ = new BehaviorSubject<AccountInfo | null>(this.getMsalAccount());
	private _myAccessProfile$ = new BehaviorSubject<UserProfile | null>(this.getMyAccessAccount());

	constructor(private _msalService: MsalService, private _stashService: StashService) {}

	get msalAccount$() {
		return this._msalAccount$;
	}

	get myAccessProfile$() {
		return this._myAccessProfile$;
	}

	setMsalAccount(account: AccountInfo) {
		this._msalAccount$.next(account);
	}

	setMyAccessAccount(userProfile: UserProfile) {
		this._myAccessProfile$.next(userProfile);
		this._stashService.setInSessionStorage(environment.myAccessProfileKey, JSON.stringify(userProfile));
	}

	clearProfileData() {
		this._msalAccount$.next(null);
		this._stashService.removeFromSessionStorage(environment.myAccessProfileKey);
		this._stashService.removeFromSessionStorage(environment.idTokenKey);
		this._stashService.removeFromSessionStorage(environment.accessTokenKey);
		this._stashService.removeFromSessionStorage(environment.userOid);
		this._stashService.removeFromSessionStorage(environment.userName);
	}

	private getMyAccessAccount() {
		return this._stashService.getObjectFromSessionStorage(environment.myAccessProfileKey);
	}

	private getMsalAccount() {
		return this._msalService.instance.getAllAccounts()[0];
	}
}
