import { Injectable } from '@angular/core';
import { Protask, ProtaskMessage } from 'src/app/core/models/domain.models';
import { ReducedDates } from './models/chat.types';
import * as dateFunctions from 'date-fns';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ChatService {
	private _messageSubmitted$ = new Subject();
	private readonly _daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	get submitterListener$() {
		return this._messageSubmitted$.pipe(delay(400));
	}

	emitNewMessageSubmittion() {
		this._messageSubmitted$.next();
	}

	addNewMessageToChat(message: ProtaskMessage, reducedListOfDates: ReducedDates[], currentTask: Protask) {
		const reducedDate = this.reducedDates([message]);
		reducedDate[0].formattedDate = this.getDateName(reducedDate[0].date, currentTask);
		this.mergeReducedDate(reducedDate[0], reducedListOfDates);
	}

	attachEventStatusToFormattedDateKey(reducedListOfDates: ReducedDates[], currentTask: Protask) {
		reducedListOfDates.forEach(
			(dateData) => (dateData.formattedDate = this.getDateName(dateData.date, currentTask))
		);
		this.insertEventNames(reducedListOfDates, currentTask);
	}

	reducedDates(chatMessages: ProtaskMessage[]) {
		const dates: ReducedDates[] = [];
		const sortedMessagesByLatest = chatMessages.sort((a, b) => dateFunctions.compareAsc(a.time, b.time));
		return [...sortedMessagesByLatest].reduce((groupedList, message) => {
			const formattedDate = dateFunctions.format(message.time, 'yyyy/MM/dd');
			const isFormattedDateInGroupedList = [...groupedList]
				.map((data) => data.formattedDate)
				.includes(formattedDate);
			if (!isFormattedDateInGroupedList) {
				const allMessagesInFormattedDate = [...chatMessages].filter((message) => {
					const chatDate = dateFunctions.format(message.time, 'yyyy/MM/dd');
					return chatDate === formattedDate;
				});
				groupedList.push({
					date: message.time,
					formattedDate: formattedDate,
					listOfMessages: allMessagesInFormattedDate,
				});
				return groupedList;
			}
			return groupedList;
		}, dates);
	}

	getChatAzureUserIds(listOfMessages: ProtaskMessage[]): any[] {
		let chatAzureUserIds: any[] = [];
		for (const message of listOfMessages) {
			const aadPrincipalUserId = message.azureUserId;
			if (aadPrincipalUserId && !chatAzureUserIds.some((msg) => msg.oid === aadPrincipalUserId)) {
				chatAzureUserIds.push({
					author: message.author,
					oid: aadPrincipalUserId,
				});
			}
		}

		return chatAzureUserIds;
	}

	scrollToBottom(selector: HTMLElement): void {
		selector.scrollTop = selector.scrollHeight;
	}

	/**
	 * A functional alternative to switch / if else
	 * @returns the matched or default predicate.
	 */
	private matched = (value) => ({
		on: () => this.matched(value),
		otherwise: () => value,
	});

	private match = (value) => ({
		on: (predicate, fn) => (predicate(value) ? this.matched(fn(value)) : this.match(value)),
		otherwise: (fn) => fn(value),
	});

	private mergeReducedDate(date: ReducedDates, reducedList: ReducedDates[]) {
		const relatedReducedDateGroup = [...reducedList].find(
			(reducedDate) => reducedDate.formattedDate === date.formattedDate
		);
		this.match(relatedReducedDateGroup)
			.on(
				(relatedReducedDateGroup) => !!relatedReducedDateGroup,
				() => relatedReducedDateGroup.listOfMessages.push(date.listOfMessages[0])
			)
			.otherwise(() => reducedList.push(date));
	}

	private getDateName(date: Date, currentTask: Protask) {
		let eventName = '';
		this.match(date)
			.on(
				(date) => dateFunctions.isToday(date),
				() => (eventName = 'Today')
			)
			.on(
				(date) => dateFunctions.isYesterday(date),
				() => (eventName = 'Yesterday')
			)
			.on(
				(date) =>
					dateFunctions.isBefore(dateFunctions.startOfYesterday(), date) && dateFunctions.isThisWeek(date),
				() => (eventName = this._daysOfTheWeek[dateFunctions.getDay(date)])
			)
			.otherwise(() => (eventName = dateFunctions.format(date, 'y MMM d, h:mm a')));
		return eventName;
	}

	private insertEventNames(reducedList: ReducedDates[], currentTask: Protask) {
		const taskOpened: ReducedDates = {
			date: currentTask.openedAt,
			formattedDate: `Time Opened: ${dateFunctions.format(currentTask.openedAt, 'y MMM d, h:mm a')}`,
			listOfMessages: [],
		};
		const lastUpdated: ReducedDates = {
			date: currentTask.lastTouched,
			formattedDate: `Last Updated: ${dateFunctions.format(currentTask.lastTouched, 'y MMM d, h:mm a')}`,
			listOfMessages: [],
		};
		reducedList.push(taskOpened, lastUpdated);
		reducedList.sort((a, b) => dateFunctions.compareAsc(a.date, b.date));
	}
}
