<!-- Navigation Cards -->
<div [style.margin.px]="gridLayoutSizes.gutter">
	<mat-grid-list
		cols="{{ gridLayoutSizes.shouldStack ? '1' : '2' }}"
		[gutterSize]="gridLayoutSizes.gutter"
		rowHeight="356"
	>
		<!-- Submitted -->
		<mat-grid-tile>
			<app-chart-card
				[chartId]="'submitted'"
				[cardTitle]="'Submitted Tasks'"
				[chartData]="submittedChartData"
				[chartLables]="submittedChartLabels"
				url="tasks/submitted"
			></app-chart-card>
		</mat-grid-tile>

		<!-- Received -->
		<mat-grid-tile>
			<app-chart-card
				[chartId]="'received'"
				[cardTitle]="'Received Tasks'"
				[chartData]="receivedChartData"
				[chartLables]="receivedChartLabels"
				url="tasks/received"
			></app-chart-card>
		</mat-grid-tile>
	</mat-grid-list>

	<!-- News Cards -->
	<mat-grid-list
		[style.margin-top.px]="gridLayoutSizes.gutter"
		cols="{{ gridLayoutSizes.shouldStack ? '1' : '2' }}"
		[gutterSize]="gridLayoutSizes.gutter"
		rowHeight="658"
	>
		<!-- News & Announcements -->
		<mat-grid-tile>
			<app-news-card
				title="News & Announcements"
				urlExtension="announcements"
				[articles]="announcementsPreviews"
				[showLoading]="announcementsAreLoading"
			></app-news-card>
		</mat-grid-tile>

		<!-- Updates -->
		<mat-grid-tile>
			<app-news-card
				title="Mirlin Updates"
				urlExtension="updates"
				[articles]="updatesPreviews"
				[showLoading]="updatesAreLoading"
			></app-news-card>
		</mat-grid-tile>
	</mat-grid-list>
</div>
