import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../image-dialog/image-dialog.component';

@Component({
	selector: 'app-image-preview',
	templateUrl: './image-preview.component.html',
	styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
	@Input() boxHeight: number;
	@Input() boxWidth: number;
	@Input() attachedFile: File;
	@Output() deleteFileEvent = new EventEmitter<File>();

	isCursorHovering = false;
	imagePath: string | ArrayBuffer;
	fileName: string;

	constructor(private _dialog: MatDialog) {}

	ngOnInit(): void {
		if (this.attachedFile.type.startsWith('image/')) {
			this.handleImagePath(this.attachedFile);
		} else {
			this.fileName = this.getFileName(this.attachedFile);
		}
	}

	handleMouseOver() {
		this.isCursorHovering = true;
	}

	handleMouseLeave() {
		this.isCursorHovering = false;
	}

	deleteFile() {
		this.deleteFileEvent.emit(this.attachedFile);
	}

	openImagePreviewDialog() {
		if (!!this.imagePath) {
			this._dialog.open(ImageDialogComponent, {
				data: {
					fileName: this.attachedFile.name,
					imagePath: this.imagePath,
				},
			});
		}
	}

	private handleImagePath(file: File) {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = (extractedFileData) => {
			this.imagePath = extractedFileData.target.result;
		};
	}

	private getFileName(file: File) {
		let fileExtenstion = file.name.split('.').pop();
		if (fileExtenstion.length > 3) {
			fileExtenstion = fileExtenstion.substring(0, 3);
		}
		if (file.name.length > 11) {
			return file.name.substring(0, 7) + '....' + fileExtenstion;
		}
		return file.name;
	}
}
