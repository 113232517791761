<form [formGroup]="creationForm">
	<mat-toolbar class="page-toolbar">
		<h6>Create Task</h6>
		<span class="spacer"></span>
		<button mat-button type="button" color="primary" style="margin-right: 8px" (click)="resetForm()">
			<span class="button reset">Reset Form</span>
		</button>
		<button
			mat-flat-button
			type="submit"
			color="primary"
			[disabled]="creationForm.invalid || !!isSubmitting"
			(click)="onSubmit()"
		>
			<span class="button submit">Submit Task</span>
		</button>
	</mat-toolbar>

	<div [style.margin.px]="gridLayoutSizes.gutter">
		<mat-card class="card">
			<mat-card-content>
				<!-- Top of card Selectors  -->
				<section class="flex-column form-block">
					<!-- Service Structure -->
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Select Service Structure</mat-label>
						<mat-select
							formControlName="serviceStructure"
							(selectionChange)="onServiceStructureSelection($event)"
						>
							<mat-option
								*ngFor="let serviceStructure of serviceStructure.businessUnits"
								[value]="serviceStructure.key"
							>
								{{ serviceStructure.value }}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="creationForm.controls['serviceStructure'].hasError('required')">
							Service structure is <strong>required</strong>
						</mat-error>
					</mat-form-field>

					<!-- Service Type -->
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Select Service Type</mat-label>
						<mat-select formControlName="serviceType" (selectionChange)="onServiceTypeSelection($event)">
							<mat-option *ngFor="let serviceType of serviceTypeOptions" [value]="serviceType.key">
								{{ serviceType.value }}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="creationForm.controls['serviceType'].hasError('required')">
							Service type is <strong>required</strong>
						</mat-error>
					</mat-form-field>

					<!-- Service Request -->
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Select Service Request</mat-label>
						<mat-select formControlName="serviceRequest" (selectionChange)="onServiceRequestSelection()">
							<mat-select-trigger>{{ serviceRequestTriggerValue }}</mat-select-trigger>
							<mat-option
								style="height: auto"
								*ngFor="let serviceRequest of serviceRequestOptions"
								[value]="serviceRequest.key"
							>
								<div class="flex-column" style="padding-bottom: 5px">
									<span>{{ serviceRequest.value }}</span>
									<span class="caption">
										Response: {{ serviceRequest.responseTime }} - Completion:
										{{ serviceRequest.allowedCompletionTime }}
									</span>
									<span class="caption">{{ serviceRequest.description }}</span>
								</div>
							</mat-option>
						</mat-select>
						<mat-error *ngIf="creationForm.controls['serviceRequest'].hasError('required')">
							Service request is <strong>required</strong>
						</mat-error>
					</mat-form-field>
				</section>

				<div class="divider"></div>

				<!-- Location / Lookup / Text Forms -->
				<div *ngIf="!!serviceRequestTemplate?.templateId">
					<section *ngFor="let templateField of serviceRequestTemplate.templateFields">
						<!-- Location Field -->
						<div *ngIf="templateField.fieldType === templateFieldType.Location">
							<div class="form-block">
								<app-location-template [locationTemplate]="templateField"></app-location-template>
							</div>
							<div class="divider"></div>
						</div>

						<!-- Text Field -->
						<div *ngIf="templateField.fieldType === templateFieldType.TextOnly">
							<div class="form-block">
								<app-text-template [textTemplate]="templateField"></app-text-template>
							</div>
							<div class="divider"></div>
						</div>

						<!-- Lookup Field -->
						<div *ngIf="templateField.fieldType === templateFieldType.TextWithLookup">
							<div class="form-block">
								<app-lookup-template
									[lookupTemplate]="templateField"
									[serviceRequestKey]="creationForm.controls['serviceRequest'].value"
								></app-lookup-template>
							</div>
							<div class="divider"></div>
						</div>
					</section>
				</div>

				<!-- Bottom Selectors -->
				<section class="flex-column form-block">
					<!-- Problem Description -->
					<mat-form-field class="full-width" appearance="outline">
						<input matInput type="text" placeholder="Add a message" formControlName="message" />
					</mat-form-field>

					<!-- Reference Number -->
					<mat-form-field class="full-width" appearance="outline">
						<input
							matInput
							type="text"
							placeholder="Add Reference Number"
							formControlName="referenceNumber"
						/>
					</mat-form-field>

					<!-- File Uploader -->
					<app-file-uploader></app-file-uploader>
				</section>
			</mat-card-content>
		</mat-card>
	</div>
</form>
