import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, skipWhile, tap } from 'rxjs/operators';
import { Protask, ProtaskMessage } from 'src/app/core/models/domain.models';
import { ProtaskUserRole } from 'src/app/core/models/enumerations';
import { DomainModelFactoryService } from 'src/app/core/services/domain-model-factory.service';
import { CreateTaskData } from '../create-protask/models/create-task.models';
import { TaskHttpService } from './task-http.service';
import { TaskStoreService } from './task-store.service';

@Injectable({ providedIn: 'root' })
export class TaskConductorService {
	constructor(
		private _taskStoreService: TaskStoreService,
		private _taskHttpService: TaskHttpService,
		private _modelFactory: DomainModelFactoryService
	) {}

	getAllTasksInStore$(): Observable<Protask[]> {
		return this._taskStoreService.activeTasks$.pipe(
			map((tasks) =>
				tasks.filter(
					(task, index, taskList) =>
						index === taskList.findIndex((taskForIndex) => taskForIndex.id === task.id)
				)
			)
		);
	}

	getAllTasksInStore(): Protask[] {
		return this._taskStoreService.activeTasks;
	}

	getTaskById$(id: number): Observable<Protask> {
		return this._taskStoreService.getTaskById$(id).pipe(
			skipWhile((task) => !task),
			first()
		);
	}

	getTaskById(id: number): Protask {
		return this._taskStoreService.getTaskById(id);
	}

	addTask(task: Protask): void {
		this._taskStoreService.addTask(task);
	}

	addListOfTasks(tasks: Protask[]): void {
		tasks.forEach((task) => this._taskStoreService.addTask(task));
	}

	emptyTasksInStore(): void {
		this._taskStoreService.emptyTasksInStore();
	}

	updateTask(newTask: Protask): void {
		this._taskStoreService.updateTask(newTask);
	}

	removeTask(taskToRemove: Protask): void {
		this._taskStoreService.removeTask(taskToRemove);
	}

	loadActiveTasks$(): Observable<Protask[]> {
		const currentActiveTasksInStore = this._taskStoreService.activeTasks;
		if (currentActiveTasksInStore.length === 0) {
			console.log('Downloading Active Tasks');
			const downloadedTasks$ = this._taskHttpService.downloadActiveTasks();
			downloadedTasks$.forEach((activeTasks) => this._taskStoreService.addListOfTasks(activeTasks));
			return downloadedTasks$;
		}
		console.log('Active Tasks Are In Store');
		return this._taskStoreService.activeTasks$.pipe(first());
	}

	loadActiveTaskComments$(taskId: number): Observable<ProtaskMessage[]> {
		return this._taskHttpService.downloadActiveTaskComments(taskId);
	}

	loadReportTask$(taskId: number): Observable<Protask> {
		const reportTaskInStore = this._taskStoreService.getTaskById(taskId);
		if (!reportTaskInStore) {
			console.log('Downloading Report Task');
			return this._taskHttpService.downloadReportTask(taskId);
		}
		console.log('Report Task Is In Store');
		return this._taskStoreService.getTaskById$(taskId).pipe(first());
	}

	loadReportComments$(taskId: number): Observable<ProtaskMessage[]> {
		return this._taskHttpService.downloadReportComments(taskId);
	}

	loadCustomFieldLookup$(serviceId: number, templateFieldId: number, query: string): Observable<any[]> {
		return this._taskHttpService.downloadCustomFieldLookup(serviceId, templateFieldId, query);
	}

	// Ideal postComments$() - Server can't take both a file and comment at the same time!
	// postComments$(comments: { protaskId: number; comment: string; attachment: File }): Observable<ProtaskMessage> {
	// 	const formData = new FormData();
	// 	formData.append('message', comments.comment);
	// 	formData.append('files', comments.attachment, comments.attachment.name);
	// 	return this._taskHttpService.postFormData(comments.protaskId, formData);
	// }

	postComment$(taskId: number, comment: string): Observable<ProtaskMessage> {
		const formData = new FormData();
		formData.append('message', comment);
		return this._taskHttpService
			.postFormData(taskId, formData)
			.pipe(map((protaskDto) => this._modelFactory.buildProtaskMessage(protaskDto)));
	}

	postFile$(taskId: number, file: File): Observable<ProtaskMessage> {
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this._taskHttpService
			.postFormData(taskId, formData)
			.pipe(map((protaskDto) => this._modelFactory.buildProtaskMessage(protaskDto)));
	}

	createTask$(fields: CreateTaskData): Observable<any> {
		return this._taskHttpService.createTask(fields);
	}

	cancelTask$(taskId: number): Observable<Protask> {
		return this._taskHttpService.cancelTask(taskId).pipe(
			map((data) => {
				const task = this._modelFactory.buildProtask(data);
				this._taskStoreService.updateTask(task);
				return task;
			})
		);
	}

	claimTask$(taskId: number): Observable<Protask> {
		return this._taskHttpService.claimTask(taskId).pipe(
			map((data) => {
				const protask = this._modelFactory.buildProtask(data);
				this._taskStoreService.updateTask(protask);
				return protask;
			})
		);
	}

	completeTask$(taskId: number): Observable<Protask> {
		return this._taskHttpService.completeTask(taskId).pipe(
			map((data) => {
				const protask = this._modelFactory.buildProtask(data);
				this._taskStoreService.updateTask(protask);
				return protask;
			})
		);
	}

	closeTask$(taskId: number, rating: number, comments: string): Observable<Protask> {
		return this._taskHttpService.closeTask(taskId, rating, comments).pipe(
			map((data) => {
				const protask = this._modelFactory.buildProtask(data);
				this._taskStoreService.updateTask(protask);
				return protask;
			})
		);
	}

	resubmitTask$(taskId: number): Observable<Protask> {
		return this._taskHttpService.resubmitTask(taskId).pipe(
			map((data) => {
				const protask = this._modelFactory.buildProtask(data);
				this._taskStoreService.updateTask(protask);
				return protask;
			})
		);
	}

	toggleTaskWatch$(task: Protask, isEnabled: boolean) {
		return this._taskHttpService.toggleTaskWatch(task.id, isEnabled).pipe(
			tap(() => {
				task.isWatched = isEnabled;
				this._taskStoreService.updateTask(task);
			})
		);
	}

	getNeededPathFromTaskMessage(message: ProtaskMessage) {
		const taskLinkedToMessage = this.getTaskById(message.protaskId);
		return taskLinkedToMessage.userRole === ProtaskUserRole.Provider ? 'received' : 'submitted';
	}
}
