<mat-toolbar class="page-toolbar">
	<div class="start-section">
		<app-breadcrumb></app-breadcrumb>
	</div>
	<div class="end-section">
		<app-dynamic-filter></app-dynamic-filter>
	</div>
</mat-toolbar>
<div [style.margin.px]="gridLayoutSizes.gutter">
	<mat-grid-list cols="1" [gutterSize]="gridLayoutSizes.gutter" rowHeight="428">
		<mat-grid-tile>
			<mat-card>
				<h5>Company</h5>
				<mat-grid-list cols="2" [gutterSize]="gridLayoutSizes.gutter" rowHeight="299">
					<mat-grid-tile>
						<app-info-card class="thin-border" title="Section Title" subtitle="This is filler text"
							iconName="help" link="settings"></app-info-card>
					</mat-grid-tile>
					<mat-grid-tile>
						<app-info-card class="thin-border" title="Section Title" subtitle="This is filler text"
							iconName="help" link="/settings"></app-info-card>
					</mat-grid-tile>
				</mat-grid-list>
			</mat-card>
		</mat-grid-tile>
		<mat-grid-tile>
			<mat-card>
				<h5>Team Management</h5>
				<mat-grid-list cols="2" [gutterSize]="gridLayoutSizes.gutter" rowHeight="286">
					<mat-grid-tile>
						<app-info-card class="thin-border" title="Section Title" subtitle="This is filler text"
							iconName="help" link="settings"></app-info-card>
					</mat-grid-tile>
					<mat-grid-tile>
						<app-info-card class="thin-border" title="Section Title" subtitle="This is filler text"
							iconName="help" link="/settings"></app-info-card>
					</mat-grid-tile>
				</mat-grid-list>
			</mat-card>
		</mat-grid-tile>
	</mat-grid-list>
</div>
