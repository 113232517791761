<mat-card class="grid-card">
	<h6>{{ cardTitle }}</h6>
	<mat-card-content>
		<div style="display: flex; justify-content: center">
			<canvas
				id="{{ chartId }}"
				style="letter-spacing: 0.4px"
				width="300"
				height="220"
				[ngClass]="{ pointer: !!url }"
				(click)="navigate()"
			></canvas>
		</div>
		<div class="legend-wrapper">
			<section class="legend">
				<span style="color: #4caf50">{{ chartData.safeCount }}</span>
				<span style="color: #ff9800">{{ chartData.warningCount }}</span>
				<span style="color: #db0028">{{ chartData.dangerCount }}</span>
			</section>
		</div>
	</mat-card-content>
</mat-card>
