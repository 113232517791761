import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from './utilities/services/loading.service';
import { finalize} from 'rxjs/operators';
import { StashService } from './utilities/services/stash.service';
import { environment } from 'src/environments/environment';
import { HandleHttpErrorService } from './handle-http-error.service';
import { HttpRequestService } from './utilities/services/http-request.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
	constructor(
		private _loadingService: LoadingService,
		private _stashService: StashService,
		private _handleHttpErrorService: HandleHttpErrorService,
		private _httpRequestService: HttpRequestService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this._loadingService.setLoadingState(true);

		const { idToken, accessToken } = this._stashService.getTokens();
		const token = this.endpointNeedsIdToken(req.url) ? idToken : accessToken;

		return next.handle(this._httpRequestService.setAuthorizationHeader(req, token)).pipe(
			this._handleHttpErrorService.handle401Error(req, next),
			finalize(() => this._loadingService.setLoadingState(false)),
		);
	}

	private endpointNeedsIdToken(url: string): boolean {
		return url.startsWith(environment.webApiUrl);
	}

}
