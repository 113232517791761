import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProtaskMessage, Protask } from '../models/domain.models';
import { ProtaskOperation, ProtaskUserRole } from '../models/enumerations';
import { TaskStoreService } from '../../protasks/services/task-store.service';
import { HelperService } from 'src/app/utilities/services/helper.service';
import { TaskConductorService } from 'src/app/protasks/services/task-conductor.service';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	constructor(
		private _snackbar: MatSnackBar,
		private _router: Router,
		private _zone: NgZone,
		private _taskStore: TaskStoreService,
		private _taskConductor: TaskConductorService,
		private _helperService: HelperService
	) {}

	requestNotificationPermission() {
		// Check if notifications are supported on users browser
		if (!Notification) {
			alert('Desktop notifications are not supported in your browser');
			return;
		} else if (Notification.permission !== 'granted') {
			Notification.requestPermission();
		}
	}

	notifyOnNewTaskMessageReceived(protaskMessage: ProtaskMessage): void {
		const title = `New comment received on task #${protaskMessage.protaskId} from ${protaskMessage.author}`;
		const receivedOrSubmitted = this._taskConductor.getNeededPathFromTaskMessage(protaskMessage);
		const route = `/tasks/${receivedOrSubmitted}/${protaskMessage.protaskId}`;

		if (!!Notification && Notification.permission === 'granted') {
			const options: NotificationOptions = { body: protaskMessage?.text };
			this.sendNotification(title, options, route);
		} else {
			const action = 'View New Comment';
			this.sendSnackBarNotification(title, action, route);
		}
	}

	notifyOnTaskUpdate(task: Protask, protaskOperation: ProtaskOperation): void {
		let message: string = '';
		const snackbarAction = this.getStateChangeAction(task);
		const receivedOrSubmitted = task.userRole === ProtaskUserRole.Provider ? 'received' : 'submitted';
		const route = `/tasks/${receivedOrSubmitted}/${task.id}`;

		switch (protaskOperation) {
			case ProtaskOperation.CreateProtask:
				message = `Received a new task #${task.id} from ${task.submitter}`;
				break;

			case ProtaskOperation.ClaimOpenProtask:
				message = `Task #${task.id} was claimed by ${task.provider}`;
				break;

			case ProtaskOperation.CompleteInProgressProtask:
				message = `Task #${task.id} was completed`;
				break;

			case ProtaskOperation.ResubmitCompletedProtask:
				message = `Task #${task.id} was resubmitted and is back in-progress`;
				break;

			case ProtaskOperation.CloseCompletedProtask:
			case ProtaskOperation.InstantCloseInProgressProtask:
				message = `Task #${task.id} is now closed`;
				break;

			case ProtaskOperation.CancelOpenProtask:
			case ProtaskOperation.InstantCancelInProgressProtask:
			case ProtaskOperation.AcknowledgeCancelPending:
				message = `Task #${task.id} was canceled`;
				break;

			case ProtaskOperation.CancelInProgressProtask:
				message = `Cancelation request has been made for task #${task.id}`;
				break;

			case ProtaskOperation.InstantClaim:
				message = `Task ${task.id} has been auto-assigned to ${task.provider}`;
				break;

			default:
				break;
		}

		if (!!Notification && Notification.permission === 'granted' && !!message.length) {
			this.sendNotification(message, null, route);
		} else if (!!message.length) {
			this.sendSnackBarNotification(message, snackbarAction, route);
		}
	}

	private sendNotification(title: string, options: NotificationOptions = null, route: string = null) {
		const notification = new Notification(title, options);
		notification.onclick = () => {
			if (!!route) {
				this._zone.run(() => this._router.navigateByUrl(route));
			}
		};
	}

	private sendSnackBarNotification(message: string, action: string, route: string = null) {
		const snackbarRef = this._snackbar.open(message, action);
		snackbarRef
			.onAction()
			.toPromise()
			.then(() => {
				if (!!route) {
					this._zone.run(() => this._router.navigateByUrl(route));
				}
			});
	}

	private doesTaskExistInStore(task: Protask): boolean {
		const taskInStore = this._taskStore.getTaskById(task.id);
		return !!taskInStore ? true : false;
	}

	private isProtaskDetailPageOpenForTheRelatedProtask(protaskId: number) {
		return this._helperService.checkIfUserIsOnDetailsPage(protaskId);
	}

	private getStateChangeAction(task: Protask): string {
		const isUserOnDetailsPage = this.isProtaskDetailPageOpenForTheRelatedProtask(task.id);
		const isTaskInStore = this.doesTaskExistInStore(task);
		return isTaskInStore && !isUserOnDetailsPage ? 'View Task' : '';
	}
}
