import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterBase } from '../models/filter-base';

@Component({
	selector: 'app-dynamic-filter-drawer',
	templateUrl: './dynamic-filter-drawer.component.html',
	styleUrls: ['./dynamic-filter-drawer.component.css'],
})
export class DynamicFilterDrawerComponent implements OnInit {
	@Input() filter!: FilterBase<any>;
	@Input() form: FormGroup;
	dateRangeKey: string;

	@Output() submitWhenSelected: EventEmitter<any> = new EventEmitter();

	get isValid() {
		return this.form.controls[this.filter.key]?.valid;
	}

	ngOnInit(): void {
		this.dateRangeKey = this.filter?.storageKey;
	}

	onChange() {
		this.submitWhenSelected.emit();
	}
}
