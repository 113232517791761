<div>
	<div mat-dialog-title>Logout</div>

	<mat-dialog-content>Are you sure you want to logout of GoRight?</mat-dialog-content>

	<mat-dialog-actions id="button-row">
		<button mat-flat-button (click)="cancel()">No</button>
		<button mat-flat-button color="primary" (click)="logout()">Yes</button>
	</mat-dialog-actions>
</div>
