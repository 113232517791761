import { Injectable } from '@angular/core';
import { RouteSegment } from '../models/shared.types';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
	private _splitRouteSegments: string[] = [];
	private _listOfRouteSegments: RouteSegment[] = [];

	tokenizeUrl(url: string) {
		this._listOfRouteSegments = [];
		this._splitRouteSegments = this.splitUrl(url);
		[...this._splitRouteSegments].forEach((urlSegment) => {
			const routeSegment = this.formatUrl(urlSegment);
			this._listOfRouteSegments.push(routeSegment);
		});
		return this._listOfRouteSegments;
	}

	private splitUrl(url: string) {
		const splitUrl = url.split('/');
		splitUrl.shift();
		return splitUrl;
	}

	private buildUrlPath(lastUrlSegment: string) {
		if (this._splitRouteSegments.length < 1) {
			return lastUrlSegment;
		}
		let builtUrl = '';
		const indexOfLastUrlSegment = [...this._splitRouteSegments].findIndex((segment) => segment === lastUrlSegment);
		this._splitRouteSegments.forEach((segment, index) => {
			if (index <= indexOfLastUrlSegment) {
				builtUrl += segment + '/';
			}
		});
		return builtUrl;
	}

	private formatStringUrl(urlSegment: string) {
		// Replace any dashed with spaces
		const spacedTitle = urlSegment
			.replace('-', ' ')
			.split('%20')
			.join(' ')
			.replace(/([a-z])([A-Z])/g, '$1 $2');

		// Split the words into an array
		const splitSpacedTitle = spacedTitle.split(' ');

		// Format each index into a title
		return splitSpacedTitle.map((data) => data.charAt(0).toUpperCase() + data.slice(1)).join(' ');
	}

	private formatNumericUrl(urlSegment: string) {
		const indexOfUrlSegment = [...this._splitRouteSegments].findIndex((segment) => segment === urlSegment);
		const previousSegment = this._listOfRouteSegments[indexOfUrlSegment - 1];
		const builtUrl = previousSegment.url + urlSegment + '/';
		const urlTitle = 'ID: ' + urlSegment;
		return <RouteSegment>{ url: builtUrl, title: urlTitle };
	}

	private formatUrl(urlSegment: string) {
		// Format the url as a string if it is not a number
		if (isNaN(+urlSegment)) {
			const formattedUrl = this.formatStringUrl(urlSegment);
			const builtUrl = this.buildUrlPath(urlSegment);
			return <RouteSegment>{ url: builtUrl, title: formattedUrl };
		}

		// Format the url as a number
		else {
			return this.formatNumericUrl(urlSegment);
		}
	}
}
