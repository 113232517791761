<form formGroupName="dateRangeForm" class="flex-container">
	<mat-form-field class="select-styles" appearance="outline">
		<mat-select formControlName="dateOpt">
			<mat-option *ngFor="let dateRange of dateRanges" [value]="dateRange.value">{{
				dateRange.viewValue
				}}</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field *ngIf="dateRangeForm.get('dateOpt').value === 'custom'" class="date-range-styles"
		appearance="outline">
		<mat-label>Enter a date range</mat-label>
		<mat-date-range-input [rangePicker]="picker">
			<input matStartDate formControlName="startDate" placeholder="Start date" />
			<input matEndDate formControlName="endDate" placeholder="End date" />
		</mat-date-range-input>
		<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
		<mat-error *ngIf="dateRangeForm.controls.startDate.hasError('matStartDateInvalid')">Invalid start date
		</mat-error>
		<mat-error *ngIf="dateRangeForm.controls.endDate.hasError('matEndDateInvalid')">Invalid end date</mat-error>
	</mat-form-field>
</form>
