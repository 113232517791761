export enum GpsFixStatus {
	None,
	Ok,
	UnableToAquireFix,
	NotSupported,
	NotEnabled,
	PermissionDenied,
	Timeout,
	Fallback,
	UserDeclinedToEnableLocationServices,
	WebApp,
}

export enum HumanizedGpsFixStatus {
	None = '--',
	Ok = 'Valid',
	UnableToAquireFix = 'Unable to aquire GPS fix',
	NotSupported = 'GPS not supported',
	NotEnabled = 'GPS disabled',
	PermissionDenied = 'Permission Denied',
	Timeout = 'GPS timed out',
	Fallback = 'Fallback',
	UserDeclinedToEnableLocationServices = 'User declined to enable GPS',
	WebApp = 'Web app used',
}

export enum HumanizedProtaskState {
	None = '--',
	Open = 'Open',
	InProgress = 'In Progress',
	Complete = 'Complete',
	Closed = 'Closed',
	CancelPending = 'Cancel Pending',
	Canceled = 'Canceled',
}
