import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateField } from 'src/app/core/models/domain.models';
import { TemplateType } from 'src/app/protasks/create-protask/models/create-task.types';
import { DataSyncAction } from '../../core/models/enumerations';

const STATE_TEXT = [
	'None',
	'Open',
	'In-Progress',
	'Complete',
	'Closed',
	'Cancel Pending',
	'Canceled',
];

@Injectable({ providedIn: 'root' })
export class HelperService {
	private _uniqueControlId = 0;

	constructor(private _router: Router) {}

	stateToText(state: number): string {
		return STATE_TEXT[state];
	}

	formatUserName(firstName: string, lastName: string, defaultValue?: string): string {
		if (firstName && lastName) {
			return `${firstName} ${lastName}`;
		}

		if (firstName) {
			return firstName;
		}

		if (lastName) {
			return lastName;
		}

		return defaultValue ? defaultValue : '';
	}

	shouldNotifyUser(action: DataSyncAction): boolean {
		switch (action) {
			case DataSyncAction.ClearAndNotify:
			case DataSyncAction.SetAndNotify:
			case DataSyncAction.SetAndNotifyDueToCollaboration:
			case DataSyncAction.SetAndNotifyDueToCollaborationButClearIfCollaborationNotSupported:
				return true;
			default:
				return false;
		}
	}

	checkIfUserIsOnDetailsPage(protaskId: number): boolean {
		const currentPage = this._router.url;
		if (
			currentPage.endsWith(`/received/${protaskId}`) ||
			currentPage.endsWith(`/submitted/${protaskId}`)
		) {
			return true;
		}
		return false;
	}
}
