<section class="flex-column">
	<mat-form-field class="full-width" appearance="outline">
		<mat-label>{{ textTemplate.label }}</mat-label>
		<textarea
			matInput
			type="text"
			[formControl]="textControl"
			cdkTextareaAutosize
			cdkAutosizeMinRows="1"
			[cdkAutosizeMaxRows]="textTemplate.numberOfRows"
			[maxlength]="textTemplate.maxCharacterLength"
			#input
		></textarea>
		<mat-hint align="end"
			>{{ input.value.length }} /
			{{ textTemplate.maxCharacterLength }}</mat-hint
		>
		<mat-error *ngIf="textControl.hasError('required')">
			Field is <strong>required</strong>
		</mat-error>
	</mat-form-field>
</section>
