<form *ngIf="filters" (ngSubmit)="onSubmit()" [formGroup]="form" class="dynamic-form">

	<div *ngFor="let filter of filters">
		<app-dynamic-filter-drawer [filter]="filter" [form]="form"
			(submitWhenSelected)="filter.automaticSubmit ? onSubmit() : undefined">
		</app-dynamic-filter-drawer>
	</div>

	<div>
		<!-- <button type="submit" mat-flat-button [disabled]="!form.valid">
			<mat-icon>search</mat-icon>
		</button> -->
	</div>

</form>
