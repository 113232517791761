// Angular Material
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

// Syncfusion
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';

const materialModules = [
	MatTooltipModule,
	MatToolbarModule,
	MatButtonModule,
	MatSidenavModule,
	MatIconModule,
	MatListModule,
	MatFormFieldModule,
	MatInputModule,
	MatCardModule,
	MatTabsModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatAutocompleteModule,
	MatSelectModule,
	MatDialogModule,
	MatRippleModule,
	MatGridListModule,
	MatMenuModule,
	DropDownListModule,
	MatExpansionModule,
	MatButtonToggleModule,
	MatPaginatorModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatNativeDateModule,
	DragDropModule,
	MatChipsModule
];

@NgModule({
	imports: materialModules,
	exports: materialModules,
})
export class MaterialModule {}
