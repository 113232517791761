import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EndpointsService {
	private readonly CLIENT_CAPABILITIES = 67;
	private readonly BASE_URL = environment.webApiUrl;

	getServiceStructureEndpoint(): string {
		return `${this.BASE_URL}/api/v1/serviceStructures`;
	}

	postProtask(): string {
		return `${this.BASE_URL}/api/v3/protasks`;
	}

	getActiveProtasks(): string {
		return `${this.BASE_URL}/api/v3/protaskboard?c=false&cf=true&cc=${this.CLIENT_CAPABILITIES}`;
	}

	lookupEndpoint(serviceId: number, templateFieldId: number, filter: string): string {
		return `${this.BASE_URL}/api/v1/lookup?s=${serviceId}&t=${templateFieldId}&f=${filter}`;
	}

	getProtaskComments(protaskId: number): string {
		return `${this.BASE_URL}/api/v3/protasks/${protaskId}/comments`;
	}

	transitionProtaskState(): string {
		return `${this.BASE_URL}/api/v3/protasks/transition`;
	}

	getMyAccess(): string {
		return `${this.BASE_URL}/api/v2/userservices/myaccess`;
	}

	postMessage(protaskId: number): string {
		return `${this.BASE_URL}/api/v5/protasks/${protaskId}/comments`;
	}

	postMessageAttachment(protaskId: number): string {
		return `${this.BASE_URL}/api/v3/protasks/${protaskId}/comments`;
	}

	runDashboardReport(): string {
		return `${this.BASE_URL}/api/v2/reports/2`;
	}

	runServiceRatingReport(): string {
		return `${this.BASE_URL}/api/v2/reports/3`;
	}

	tokenService(): string {
		return `${this.BASE_URL}/token`;
	}

	toggleProtaskWatch(protaskId: number, isEnabled: boolean): string {
		return `${this.BASE_URL}/api/v2/protasks/${protaskId}/watch/${isEnabled}`;
	}

	getProtaskDrilldownForReport(protaskId: number): string {
		return `${this.BASE_URL}/api/v2/reports/drilldown/${protaskId}`;
	}

	runGeofenceValidationReport(): string {
		return `${this.BASE_URL}/api/v2/reports/4`;
	}

	getPhotoReportServiceChannels(): string {
		return `${this.BASE_URL}/api/v2/reports/1/lookup/channels`;
	}

	getPhotoReportAssetCodes(channelId: number): string {
		return `${this.BASE_URL}/api/v2/reports/1/lookup/assetCodes?channelId=${channelId}`;
	}

	getPhotoReportAssetHistory(assetCode: string, channelId: number): string {
		return `${this.BASE_URL}/api/v2/reports/1/lookup/assetHistory?channelId=${channelId}&assetCode=${assetCode}`;
	}

	getPhotoReportResults() {
		return `${this.BASE_URL}/api/v2/reports/1/`;
	}

	getDefaultProfilePicUrl(loginHash: string) {
		return `https://www.gravatar.com/avatar/${loginHash}?r=g&d=mm&s=40`;
	}

	getArticleFiltersEndpoint() {
		return `${this.BASE_URL}/api/v1/articles/filters`;
	}

	getArticleEndpoint(articleId: number) {
		return `${this.BASE_URL}/api/v1/articles/${articleId}`;
	}

	getArticlePreviewEndpoint(sectionId: number) {
		return `${this.BASE_URL}/api/v1/articles/previews/sections/${sectionId}`;
	}

	getSelectArticlePreviewsEndpoint(sectionId: number, numberOfArticles: number) {
		return `${this.BASE_URL}/api/v1/articles/previews/sections/${sectionId}?numItems=${numberOfArticles}`;
	}
}
