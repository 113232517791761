import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFilterMiddlewareService } from '../../services/dynamic-filter-middleware.service';
import { DynamicFilterService } from '../../services/dynamic-filter.service';
import { FilterBase } from '../models/filter-base';

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.css'],
	providers: [DynamicFilterService],
})
export class DynamicFormComponent implements OnInit {
	@Input() filters: FilterBase<any>[] | null = [];
	form!: FormGroup;

	constructor(
		private _dynamicFilterService: DynamicFilterService,
		private _dynamicFilter: DynamicFilterMiddlewareService
	) {}

	ngOnInit() {
		this.form = this._dynamicFilterService.toFormGroup(this.filters as FilterBase<any>[]);
	}

	onSubmit() {
		this._dynamicFilter.sendFiltersValue(this.form.getRawValue());
	}
}
