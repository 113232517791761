import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImagePreviewComponent } from '../file-uploader/image-preview/image-preview.component';

@Component({
	selector: 'app-image-dialog',
	templateUrl: './image-dialog.component.html',
	styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent implements OnInit {
	fileName: string = '';
	imagePath: string | ArrayBuffer;

	constructor(
		private _dialogRef: MatDialogRef<ImagePreviewComponent>,
		@Inject(MAT_DIALOG_DATA)
		private data: { fileName: string; nonImagePath: string; imagePath: string }
	) {}

	ngOnInit(): void {
		console.log(this.data);
		this.fileName = this.data.fileName;
		this.imagePath = this.data.imagePath;
	}

	closeDialog() {
		this._dialogRef.close();
	}
}
