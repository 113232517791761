import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointsService } from '../../core/services/endpoints.service';
import { Article, ArticleFilters } from './models/news.models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NewsHttpService {
	constructor(private _http: HttpClient, private _endpoints: EndpointsService) {}

	downloadArticleFilters() {
		const url = this._endpoints.getArticleFiltersEndpoint();
		return this._http.get<ArticleFilters>(url);
	}

	downloadArticlePreview(sectionId: number) {
		const url = this._endpoints.getArticlePreviewEndpoint(sectionId);
		return this._http.get<Article[]>(url).pipe(map((articles) => this.sortListOfArticles(articles)));
	}

	downloadSelectArticlePreviews(sectionId: number, numberOfArticles: number) {
		const url = this._endpoints.getSelectArticlePreviewsEndpoint(sectionId, numberOfArticles);
		return this._http.get<Article[]>(url).pipe(map((articles) => this.sortListOfArticles(articles)));
	}

	downloadArticle(articleId: number) {
		const url = this._endpoints.getArticleEndpoint(articleId);
		return this._http.get<Article>(url);
	}

	private sortListOfArticles(articles: Article[]) {
		return [...articles].sort((a, b) => a?.do - b?.do);
	}
}
