import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { SubSink } from 'subsink';

import { DateRange } from '../../reports/shared/shared.types';
import { DATE_RANGE_FORM_NAME } from './date-range.constants';
import { DATE_RANGES } from 'src/app/reports/shared/shared.constants';

import { StashService } from 'src/app/utilities/services/stash.service';

@Component({
	selector: 'app-date-range-selector',
	templateUrl: './date-range-selector.component.html',
	styleUrls: ['./date-range-selector.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DateRangeSelectorComponent implements OnInit, OnDestroy {
	@Input() dateRangeKey: string;

	dateRanges = DATE_RANGES;
	dateRangeForm = this._fb.group({
		dateOpt: null,
		startDate: null,
		endDate: null,
	});

	private _parentForm: FormGroup;
	private _subSink = new SubSink();

	constructor(
		private _fb: FormBuilder,
		private _stashService: StashService,
		private _parentFormDirective: FormGroupDirective
	) {}

	ngOnInit(): void {
		this.attachControlsToParentForm();
		this.setFormValues();
		this.observeFormChanges();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	private attachControlsToParentForm() {
		this._parentForm = this._parentFormDirective.form;
		this._parentForm.addControl(DATE_RANGE_FORM_NAME, this.dateRangeForm);
	}

	private setFormValues() {
		const savedRange: DateRange = this._stashService.getObjectFromLocalStorage(this.dateRangeKey);
		this.dateRangeForm.get('dateOpt').setValue(savedRange.dateOpt);
		if (!!savedRange?.startDate && !!savedRange?.endDate) {
			this.dateRangeForm.get('startDate').setValue(savedRange.startDate);
			this.dateRangeForm.get('endDate').setValue(savedRange.endDate);
		}
	}

	private observeFormChanges() {
		this._subSink.sink = this.dateRangeForm.valueChanges.subscribe((data: DateRange) =>
			this._stashService.setInLocalStorage(this.dateRangeKey, JSON.stringify(data))
		);
	}
}
