import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';

// Models / Constants
import { ServiceChannelLookup, ServiceLookup, ServiceStructureLookup } from 'src/app/core/models/domain.models';
import { Template } from 'src/app/core/types/domain.types';
import { TemplateFieldType } from 'src/app/core/models/enumerations';
import { GridLayout } from 'src/app/core/types/grid-layout.type';

// Services
import { ViewportService } from 'src/app/utilities/layout/viewport.service';
import { TemplateFormService } from 'src/app/shared/template-form.service';
import { CreateTaskData } from './models/create-task.models';
import { TaskConductorService } from '../services/task-conductor.service';
import { FileUploaderService } from 'src/app/shared/file-uploader.service';
import { IProtaskDetail2Dto } from 'src/app/core/models/dto.models';
import { DomainModelFactoryService } from 'src/app/core/services/domain-model-factory.service';

@Component({
	selector: 'app-create-protask',
	templateUrl: './create-protask.component.html',
	styleUrls: ['./create-protask.component.scss'],
})
export class CreateProtaskComponent implements OnInit, OnDestroy {
	gridLayoutSizes: GridLayout;
	isSubmitting = false;
	serviceStructure: ServiceStructureLookup;
	serviceTypeOptions: ServiceChannelLookup[] = [];
	serviceRequestOptions: ServiceLookup[] = [];
	creationForm = this._fb.group({
		serviceStructure: [null, Validators.required],
		serviceType: [{ value: null, disabled: true }, Validators.required],
		serviceRequest: [{ value: null, disabled: true }, Validators.required],
		message: null,
		referenceNumber: null,
	});
	serviceRequestTemplate: Template;
	serviceRequestTriggerValue: string;

	private _subSink = new SubSink();

	constructor(
		private _fb: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		private _viewportService: ViewportService,
		private _taskConductor: TaskConductorService,
		private _templateFormService: TemplateFormService,
		private _fileUploaderService: FileUploaderService,
		private _taskBuilderService: DomainModelFactoryService
	) {}

	ngOnInit(): void {
		this.observeViewport();
		this.serviceStructure = this._route.snapshot.data.serviceStructure;
	}

	ngOnDestroy(): void {
		this.resetSubmittingState();
		this._subSink.unsubscribe();
	}

	get templateFieldType(): typeof TemplateFieldType {
		return TemplateFieldType;
	}

	onServiceStructureSelection(selection: MatSelectChange) {
		this.serviceRequestTemplate = null;
		this.creationForm.get('serviceType').setValue(null);
		this.creationForm.get('serviceRequest').setValue(null);
		this.serviceTypeOptions = this.serviceStructure.channels.filter(
			(data) => data.businessUnitId === selection.value
		);
		this.handleServiceTypeControlState();
	}

	onServiceTypeSelection(selection: MatSelectChange) {
		this.serviceRequestTemplate = null;
		this.creationForm.get('serviceRequest').setValue(null);
		this.serviceRequestOptions = this.serviceStructure.services.filter(
			(data) => data.serviceChannelId === selection.value
		);
		this.handleServiceRequestControlState();
	}

	onAutoSelectServiceType(selection: ServiceChannelLookup) {
		this.serviceRequestTemplate = null;
		this.creationForm.get('serviceRequest').setValue(null);
		this.serviceRequestOptions = this.serviceStructure.services.filter(
			(data) => data.serviceChannelId === selection.key
		);
		this.handleServiceRequestControlState();
	}

	onServiceRequestSelection() {
		// Get user input
		const serviceRequestControlValue: number = this.creationForm.get('serviceRequest').value;

		// Find the related service lookup
		const relatedServiceLookup = this.serviceRequestOptions.find(
			(lookup) => lookup.key === serviceRequestControlValue
		);

		this.serviceRequestTriggerValue = relatedServiceLookup.value;

		// Check if the related lookup has a template object
		if ('template' in relatedServiceLookup) {
			this.serviceRequestTemplate = relatedServiceLookup.template;
		} else {
			this.serviceRequestTemplate = null;
		}
	}

	resetForm() {
		this.creationForm.reset();
		this.serviceRequestTemplate = null;
		const listOfFileUploaderForms = this._fileUploaderService.getAllFileListForms(this.creationForm);
		listOfFileUploaderForms.forEach((control) => control.clear());
	}

	async onSubmit() {
		if (this.creationForm.valid) {
			this.isSubmitting = true;
			const formData = this.getNewTaskData();
			const taskDto: IProtaskDetail2Dto = await this.uploadNewTaskInfoToServer(formData).catch(() =>
				this.resetSubmittingState()
			);

			if (!!taskDto) {
				const task = this._taskBuilderService.buildProtask(taskDto);
				this._taskConductor.addTask(task);

				const attachedFiles = this.getFilesInForm();
				if (!!attachedFiles && attachedFiles.length > 0) {
					await this.uploadFiles(taskDto.id, attachedFiles);
				}

				const comment: string = this.creationForm.get('message').value;
				if (!!comment) {
					await this._taskConductor
						.postComment$(taskDto.id, comment)
						.toPromise()
						.catch(() => this.resetSubmittingState());
				}

				this._router.navigateByUrl(`/tasks/submitted/${taskDto.id}`);
			}
		}
	}

	private observeViewport() {
		this._subSink.sink = this._viewportService
			.observeViewportSize()
			.subscribe((screenSize) => (this.gridLayoutSizes = screenSize));
	}

	private resetSubmittingState() {
		this.isSubmitting = false;
	}

	private handleServiceTypeControlState() {
		const serviceTypeControl = this.creationForm.get('serviceType');
		const dataForServiceTypeControl = this.serviceTypeOptions;

		// If the control has data for it, enable it
		if (!!dataForServiceTypeControl.length) {
			serviceTypeControl.enable();

			// If there is one value, then auto set the control to it
			if (dataForServiceTypeControl.length === 1) {
				const singleValue = dataForServiceTypeControl[0].key;
				serviceTypeControl.setValue(singleValue);
				this.onAutoSelectServiceType(dataForServiceTypeControl[0]);
			}
		}

		// Otherwise keep it disabled
		else {
			serviceTypeControl.disable();
		}
	}

	private handleServiceRequestControlState() {
		const serviceTypeControlValue = this.creationForm.get('serviceType').value;
		const serviceRequestControl = this.creationForm.get('serviceRequest');
		const dataForServiceRequestControl = this.serviceRequestOptions;

		// If the control has data for it AND the serviceType control has data selected, enable it
		if (!!dataForServiceRequestControl.length && !!serviceTypeControlValue) {
			serviceRequestControl.enable();

			// If there is one value, then auto set the control to it
			if (dataForServiceRequestControl.length === 1) {
				const singleValue = dataForServiceRequestControl[0].key;
				serviceRequestControl.setValue(singleValue);
				this.onServiceRequestSelection();
			}
		}

		// Otherwise keep it disabled
		else {
			serviceRequestControl.disable();
		}
	}

	private getNewTaskData() {
		const serviceId: number = this.creationForm.get('serviceRequest').value;
		const createTaskData: CreateTaskData = { serviceId: serviceId };
		const referenceNumber = this.creationForm.get('referenceNumber').value;

		// Check if there is a reference message
		if (!!referenceNumber) {
			createTaskData.reference = referenceNumber;
		}

		// Check if there are any template fields
		if (!!this.serviceRequestTemplate) {
			const templateFields = this._templateFormService.getFormattedTemplateFields(
				this.creationForm,
				this.serviceRequestTemplate
			);
			if ('textFields' in templateFields) {
				createTaskData.textFields = templateFields.textFields;
			}
			if ('locationFields' in templateFields) {
				createTaskData.locationFields = templateFields.locationFields;
			}
		}

		return createTaskData;
	}

	private uploadNewTaskInfoToServer(newTaskInfo: CreateTaskData) {
		return this._taskConductor
			.createTask$(newTaskInfo)
			.toPromise()
			.catch((error) => this.resetSubmittingState());
	}

	private getFilesInForm() {
		const attachedFiles: File[] = [];
		const formArrays = this._fileUploaderService.getAllFileListForms(this.creationForm);
		formArrays.forEach((formArray) => {
			if (!!formArray.value.length) {
				const files = this._fileUploaderService.getAttachedFiles(formArray);
				attachedFiles.push(...files);
			}
		});
		return attachedFiles;
	}

	private async uploadFiles(taskDtoId: number, files: File[]) {
		for (const file of files) {
			await this._taskConductor
				.postFile$(taskDtoId, file)
				.toPromise()
				.catch(() => this.resetSubmittingState());
		}
	}
}
