import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormArray,
	FormBuilder,
	FormGroup,
	FormGroupDirective,
} from '@angular/forms';
import { SubSink } from 'subsink';
import { FileUploaderService } from '../file-uploader.service';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
	isFileBeingDraggedOver = false;
	isAFileAttached = false;

	private _parentForm: FormGroup;
	private _fileListControlName: string;
	private _subSink = new SubSink();

	constructor(
		private _fb: FormBuilder,
		private _parentFormDirective: FormGroupDirective,
		private _fileUploaderService: FileUploaderService
	) {}

	ngOnInit(): void {
		this.setupFileListControl();
		this.observeValueChanges();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	get fileListForm(): FormArray {
		return this._parentForm.controls[this._fileListControlName] as FormArray;
	}

	openFileExplorer() {
		document.getElementById('hiddenInput').click();
	}

	dropHandler(dragEvent: DragEvent) {
		dragEvent.preventDefault();
		this.isFileBeingDraggedOver = false;
		this.addFileList(dragEvent.dataTransfer.files);
	}

	// Prevent default behavior - (Prevent file from being opened)
	dragOverHandler(dragEvent: DragEvent) {
		dragEvent.preventDefault();
		this.isFileBeingDraggedOver = true;
	}

	dragLeaveHandler() {
		this.isFileBeingDraggedOver = false;
	}

	onFileAddedFromExplorer(data: any) {
		this.addFileList(data.target.files);
		data.target.value = '';
	}

	deleteFile(file: File) {
		const indexToDelete = this.fileListForm.controls.findIndex(
			(fileControl) => fileControl.value.name === file.name
		);
		if (indexToDelete > -1) {
			this.fileListForm.removeAt(indexToDelete, { emitEvent: true });
		}
	}

	private setupFileListControl() {
		this._parentForm = this._parentFormDirective.form;
		this._fileListControlName = this._fileUploaderService.generateFileFormName();
		this._parentForm.addControl(this._fileListControlName, this._fb.array([]));
	}

	private addFileList(files: FileList) {
		[...files].forEach((file) => {
			const isFileAlreadyAttached = this.fileListForm.controls.find(
				(control) => control.value.name === file.name
			);
			if (!isFileAlreadyAttached) {
				this.fileListForm.push(this._fb.control(file));
				console.log('Added file', this.fileListForm.value);
			}
		});
	}

	private areAnyFilesAttached() {
		if (!!this.fileListForm.length) {
			return true;
		}
		return false;
	}

	private observeValueChanges() {
		this._subSink.sink = this.fileListForm.valueChanges.subscribe(
			(data) => (this.isAFileAttached = this.areAnyFilesAttached())
		);
	}
}
