import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';

import { Protask } from '../core/models/domain.models';
import { ProtaskState, ProtaskUserRole, TaskCondition } from '../core/models/enumerations';
import { GridLayout } from '../core/types/grid-layout.type';
import { Article } from '../shared/news-center/models/news.models';
import { ChartData, ChartLabels } from '../shared/models/shared.types';

import { ProtaskSlaComplianceService } from '../protasks/services/protask-compliance.service';
import { TaskConductorService } from '../protasks/services/task-conductor.service';
import { ViewportService } from '../utilities/layout/viewport.service';
import { NewsFilterSections } from '../shared/news-center/models/news-center.enums';
import { NewsHttpService } from '../shared/news-center/news-http.service';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
	gridLayoutSizes: GridLayout;
	submittedChartData: ChartData;
	receivedChartData: ChartData;
	announcementsPreviews: Article[] = [];
	announcementsAreLoading = false;
	updatesPreviews: Article[] = [];
	updatesAreLoading = false;
	submittedChartLabels: ChartLabels = {
		first: 'Complete',
		second: 'In Progress',
		third: 'At Risk',
	};
	receivedChartLabels: ChartLabels = {
		first: 'Complete',
		second: 'In Progress',
		third: 'At Risk',
	};

	private _subSink = new SubSink();

	constructor(
		private _newsHttpService: NewsHttpService,
		private _viewportService: ViewportService,
		private _taskConductor: TaskConductorService,
		private _slaService: ProtaskSlaComplianceService
	) {
		this.downloadNews();
		this.downloadUpdates();
	}

	ngOnInit(): void {
		this.observeViewport();
		this.observeTaskStore();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	private downloadNews() {
		this.announcementsAreLoading = true;
		const newsId = NewsFilterSections.Announcements;
		this._subSink.sink = this._newsHttpService
			.downloadSelectArticlePreviews(newsId, 3)
			.pipe(finalize(() => (this.announcementsAreLoading = false)))
			.subscribe((newsData) => (this.announcementsPreviews = newsData));
	}

	private downloadUpdates() {
		this.updatesAreLoading = true;
		const updatesId = NewsFilterSections.Updates;
		this._subSink.sink = this._newsHttpService
			.downloadSelectArticlePreviews(updatesId, 3)
			.pipe(finalize(() => (this.updatesAreLoading = false)))
			.subscribe((updatesData) => (this.updatesPreviews = updatesData));
	}

	private observeViewport() {
		this._subSink.sink = this._viewportService
			.observeViewportSize()
			.subscribe((screenSize) => (this.gridLayoutSizes = screenSize));
	}

	private observeTaskStore() {
		this._subSink.sink = this._taskConductor.getAllTasksInStore$().subscribe((tasks) => {
			this.submittedChartData = this.getSubmittedTasksChartData(tasks);
			this.receivedChartData = this.getReceivedTasksChartData(tasks);
		});
	}

	private getReceivedTasksChartData(tasks: Protask[]) {
		const receivedChartData: ChartData = { safeCount: 0, warningCount: 0, dangerCount: 0, totalCount: 0 };
		const receivedTasks = [...tasks].filter(
			(task) =>
				task.userRole === ProtaskUserRole.Provider || task.userRole === ProtaskUserRole.ServiceChannelProviders
		);
		receivedChartData.totalCount = receivedTasks.length;
		receivedTasks.forEach((task) => {
			const condition = this._slaService.getProtaskSlaCompliance(task);

			switch (task.state) {
				case ProtaskState.Complete:
					receivedChartData.safeCount++;
					break;
				case ProtaskState.InProgress:
					receivedChartData.warningCount++;
					break;
				default:
					break;
			}

			if (condition?.condition === TaskCondition.Danger) {
				receivedChartData.dangerCount++;
			}
		});
		return receivedChartData;
	}

	private getSubmittedTasksChartData(tasks: Protask[]) {
		const submittedChartData: ChartData = { safeCount: 0, warningCount: 0, dangerCount: 0, totalCount: 0 };
		const submittedTasks = [...tasks].filter(
			(task) =>
				task.userRole === ProtaskUserRole.Submitter ||
				task.userRole === ProtaskUserRole.ServiceChannelSubmitters
		);
		submittedChartData.totalCount = submittedTasks.length;
		submittedTasks.forEach((task) => {
			const condition = this._slaService.getProtaskSlaCompliance(task);

			switch (task.state) {
				case ProtaskState.Complete:
					submittedChartData.safeCount++;
					break;
				case ProtaskState.InProgress:
					submittedChartData.warningCount++;
					break;
				default:
					break;
			}

			if (condition?.condition === TaskCondition.Danger) {
				submittedChartData.dangerCount++;
			}
		});
		return submittedChartData;
	}
}
