import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ProtaskMessage, Protask, ServiceStructureLookup } from '../core/models/domain.models';
import { ProfileHttpService } from '../core/services/profile/profile-http.service';
import { ServiceStructureService } from '../core/services/service-structure.service';
import { ChatService } from './details/chat/chat.service';
import { TaskConductorService } from './services/task-conductor.service';

@Injectable()
export class ProtaskMessageResolver implements Resolve<ProtaskMessage[]> {
	constructor(
		private _taskConductor: TaskConductorService,
		private _chatService: ChatService,
		private _profileHttpService: ProfileHttpService
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<ProtaskMessage[]> {
		const taskId = +route.paramMap.get('taskId');
		return this._taskConductor.loadActiveTaskComments$(taskId).pipe(
			map((messages) => {
				const authorNamesAndAadUserIds = this._chatService.getChatAzureUserIds(messages);
				authorNamesAndAadUserIds.forEach(async (authorNameAadUserId) => {
					this._profileHttpService.callUsersProfilePicture(authorNameAadUserId.oid).then(() => {});
				});

				return messages;
			}),
			delay(300)
		);
	}
}

@Injectable()
export class ActiveProtaskResolver implements Resolve<Protask> {
	constructor(private _taskConductor: TaskConductorService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<Protask> {
		const taskId = +route.paramMap.get('taskId');
		return this._taskConductor.getTaskById$(taskId);
	}
}

@Injectable()
export class ServiceStructureResolver implements Resolve<ServiceStructureLookup> {
	constructor(private serviceStructureService: ServiceStructureService) {}

	resolve(): Observable<ServiceStructureLookup> {
		return this.serviceStructureService.loadServiceStructure();
	}
}

@Injectable()
export class TasksBoardResolver implements Resolve<Protask[]> {
	constructor(private _taskConductorService: TaskConductorService) {}

	resolve(): Observable<Protask[]> {
		return this._taskConductorService.loadActiveTasks$();
	}
}
