import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { DynamicFilterMiddlewareService } from '../services/dynamic-filter-middleware.service';
import { FilterBase } from './models/filter-base';

@Component({
	selector: 'app-dynamic-filter',
	templateUrl: './dynamic-filter.component.html',
	styleUrls: ['./dynamic-filter.component.css'],
})
export class DynamicFilterComponent implements OnInit, OnDestroy {
	filters$: Observable<FilterBase<any>[]>;
	subscription$: Subscription;
	private _subSink = new SubSink();

	constructor(private _dynamicFilterMiddlewareService: DynamicFilterMiddlewareService) {}

	ngOnInit(): void {
		this._subSink.sink = this._dynamicFilterMiddlewareService.getFiltersForm().subscribe((filters) => {
			this.filters$ = filters;
		});
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}
}
