import { Injectable } from '@angular/core';
import { ProtaskMessage } from '../../models/domain.models';
import { IProtaskMessageDto } from '../../models/dto.models';
import { DataSyncAction } from '../../models/enumerations';
import { IPushModelProcessor, IPushModel } from '../../models/push.model';
import { HelperService } from '../../../utilities/services/helper.service';
import { DomainModelFactoryService } from '../domain-model-factory.service';
import { MessagingCenterService } from '../messaging-center.service';
import { SnackbarService } from '../snackbar.service';
import { TaskChangeStoreService } from './task-change-store.service';

@Injectable({ providedIn: 'root' })
export class ReceiveNewCommentService implements IPushModelProcessor {
	constructor(
		private _taskBuilder: DomainModelFactoryService,
		private _messageCenter: MessagingCenterService,
		private _helperService: HelperService,
		private _taskChangeStore: TaskChangeStoreService,
		private _snackbarService: SnackbarService
	) {}

	processPushModel(pushModel: IPushModel): void {
		console.log('NEW COMMENT', pushModel);
		const protaskMessage = this.mapDtoToProtaskMessage(pushModel.payload);

		// Add the new message the protask detail page only if it is showing the related protask.
		const isRelatedProtaskDetailPageOpen = this.isProtaskDetailPageOpenForTheRelatedProtask(
			protaskMessage.protaskId
		);

		if (isRelatedProtaskDetailPageOpen) {
			this.sendNewProtaskMessageToDetailsPage(protaskMessage);
		} else if (this.isNotificationNeeded(pushModel.action)) {
			this._taskChangeStore.addKeyToChangeSet(protaskMessage.protaskId);
			this.notifyUser(protaskMessage);
		}
	}

	private isNotificationNeeded(action: DataSyncAction): boolean {
		return this._helperService.shouldNotifyUser(action);
	}

	private mapDtoToProtaskMessage(messageDto: IProtaskMessageDto): ProtaskMessage {
		return this._taskBuilder.buildProtaskMessage(messageDto);
	}

	private isProtaskDetailPageOpenForTheRelatedProtask(protaskId: number) {
		return this._helperService.checkIfUserIsOnDetailsPage(protaskId);
	}

	private sendNewProtaskMessageToDetailsPage(protaskMessage: ProtaskMessage): void {
		this._messageCenter.publishNewProtaskMessage(protaskMessage);
	}

	private notifyUser(newProtaskMessage: ProtaskMessage): void {
		this._snackbarService.notifyOnNewTaskMessageReceived(newProtaskMessage);
	}
}
