import { Injectable } from '@angular/core';
import { addMinutes, differenceInMinutes, formatDistanceStrict } from 'date-fns';
import { ITaskTime } from 'src/app/core/models/domain.models';
import { TaskCondition, ProtaskState } from 'src/app/core/models/enumerations';

export interface ProtaskSlaCompliance {
	tracking: boolean;
	finished: boolean;
	startedAt?: Date;
	minutesAllowed?: number;
	conditionIsOk?: boolean;
	expiryTime?: Date;
	unboundMinutesRemaining?: number;
	humanTimeRemaining?: string;
	minutesRemaining?: number;
	percentRemaining?: number;
	condition?: number;
	ranking?: number;
}

@Injectable({ providedIn: 'root' })
export class ProtaskSlaComplianceService {
	private getHumanTimeRemaining(time: number): string {
		return formatDistanceStrict(0, time * 60000);
	}

	private computeExpiryTime(startTime: Date, minutesAllowed: number): Date {
		return addMinutes(startTime, minutesAllowed);
	}

	// Returns the number of minutes remaining before a protask must transition
	// into a new state.  Returns 0 if there is no more time remaining.
	private computeMinutesRemaining(expiryTime: Date): number {
		const now = new Date();
		return Math.ceil(differenceInMinutes(expiryTime, now));
	}

	private getTaskCondition(taskPercentRemaining: number): number {
		if (taskPercentRemaining > 0.5) {
			return TaskCondition.Okay;
		} else if (taskPercentRemaining > 0.25) {
			return TaskCondition.Warning;
		} else {
			return TaskCondition.Danger;
		}
	}

	getProtaskSlaCompliance(protask: ITaskTime): ProtaskSlaCompliance {
		const status: ProtaskSlaCompliance = { tracking: false, finished: true };
		switch (protask.state) {
			case ProtaskState.Open:
				status.tracking = true;
				status.finished = false;
				status.startedAt = protask.openedAt;
				status.minutesAllowed = protask.slaMinutesToRespond;
				break;
			case ProtaskState.InProgress:
				status.tracking = true;
				status.finished = false;
				status.startedAt = protask.respondedAt;
				status.minutesAllowed = protask.slaMinutesToComplete;
				break;
			case ProtaskState.Complete:
				status.tracking = false;
				status.finished = false;
				status.conditionIsOk = true;
				break;
			default:
				status.tracking = false;
				status.finished = true;
				break;
		}
		if (status.tracking) {
			status.expiryTime = this.computeExpiryTime(status.startedAt, status.minutesAllowed);
			status.unboundMinutesRemaining = this.computeMinutesRemaining(status.expiryTime);
			status.humanTimeRemaining = this.getHumanTimeRemaining(status.unboundMinutesRemaining);
			status.minutesRemaining = status.unboundMinutesRemaining < 0 ? 0 : status.unboundMinutesRemaining;
			status.percentRemaining = status.minutesRemaining / status.minutesAllowed;

			// Condition 0 -> ok
			// Condition 1 -> warning
			// Condition 2 -> danger
			status.condition = this.getTaskCondition(status.percentRemaining);
			status.conditionIsOk = status.condition === TaskCondition.Okay ? true : false;
			const statusWeighting = protask.state === ProtaskState.Open ? 1 : 2;
			status.ranking = -1 * statusWeighting * status.unboundMinutesRemaining;
		}
		return status;
	}
}
