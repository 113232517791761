import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProtaskMessage } from '../models/domain.models';

@Injectable({ providedIn: 'root' })
export class MessagingCenterService {
	private _refreshDataListener$ = new Subject<void>();
	private _tableColumnsChangesListener$ = new Subject<any[]>();
	private _newProtaskMessageListener$ = new Subject<ProtaskMessage>();

	registerForNewRefreshEvents(): Subject<void> {
		return this._refreshDataListener$;
	}

	registerForTableColumnsChanges(): Subject<any[]> {
		return this._tableColumnsChangesListener$;
	}

	registerForNewProtaskMessages(): Subject<ProtaskMessage> {
		return this._newProtaskMessageListener$;
	}

	publishNewRefreshEvent(): void {
		this._refreshDataListener$.next();
	}

	publishNewTableColumnChange<T>(listOfTableColumns: T[]): void {
		this._tableColumnsChangesListener$.next(listOfTableColumns);
	}

	publishNewProtaskMessage(protaskMessage: ProtaskMessage): void {
		this._newProtaskMessageListener$.next(protaskMessage);
	}
}
