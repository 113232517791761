import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SubSink } from 'subsink';

// Models / Constants
import { TemplateField } from 'src/app/core/models/domain.models';
import { RegionGroup } from './location.models';
import * as LocationConstants from './locations.constants';

// Latitude / Longitude Dialog
import {
	LatLngDialogResult,
	LatLongDialogComponent,
} from 'src/app/utilities/dialogs/lat-long-dialog/lat-long-dialog.component';
import { TemplateFormService } from 'src/app/shared/template-form.service';
import { TemplateType } from 'src/app/protasks/create-protask/models/create-task.types';

// import { TemplateType } from '../../protasks/create-protask/models/create-task.types';

@Component({
	selector: 'app-location-template',
	templateUrl: './location-template.component.html',
	styleUrls: ['./location-template.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class LocationTemplateComponent implements OnInit, OnDestroy {
	@Input() locationTemplate: TemplateField;
	regionOptions: RegionGroup[] = [LocationConstants.StateGroup, LocationConstants.ProvinceGroup];
	stateGroup = LocationConstants.StateGroup;
	provinceGroup = LocationConstants.ProvinceGroup;
	locationFormName: string;

	private _parentForm: FormGroup;
	private _subSink = new SubSink();

	constructor(
		private _fb: FormBuilder,
		private _dialog: MatDialog,
		private _parentFormDirective: FormGroupDirective,
		private _templateFormService: TemplateFormService
	) {}

	ngOnInit(): void {
		this.setupLocationForm();
		this.setValidatorsOnForm();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
		this._parentForm.removeControl(this.locationFormName);
	}

	get locationForm(): FormGroup {
		return this._parentForm.controls[this.locationFormName] as FormGroup;
	}

	openLocationDialog(): void {
		const dialogRef = this._dialog.open(LatLongDialogComponent);
		this._subSink.sink = dialogRef.afterClosed().subscribe((result: LatLngDialogResult) => {
			if (!!result) {
				this.locationForm.patchValue({
					latitude: result.latitude,
					longitude: result.longitude,
				});
			}
		});
	}

	private setupLocationForm() {
		this._parentForm = this._parentFormDirective.form;
		this.locationFormName = this._templateFormService.generateTemplateFormName(
			TemplateType.Location,
			this.locationTemplate.fieldId
		);
		this._parentForm.addControl(
			this.locationFormName,
			this._fb.group({
				address: null,
				city: null,
				region: null,
				latitude: null,
				longitude: null,
			})
		);
		console.log(this._parentForm);
	}

	private setValidatorsOnForm(): void {
		const validatorFunctions: ValidatorFn[] = [];
		if (this.locationTemplate.isRequired) {
			validatorFunctions.push(Validators.required);
		}
		Object.keys(this.locationForm.controls).forEach((key) => {
			this.locationForm.controls[key].setValidators(validatorFunctions);
		});
	}
}
