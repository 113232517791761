import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
	selector: 'app-changes-indicator',
	templateUrl: './changes-indicator.component.html',
	styleUrls: ['./changes-indicator.component.scss'],
})
export class ChangesIndicatorComponent implements OnInit, OnDestroy {
	@Input() numberOfChanges: number = 0;
	shouldBackgroundColourInherit = false;

	private _subSink = new SubSink();

	constructor(private _router: Router) {}

	ngOnInit(): void {
		this.observeUrl();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	private observeUrl() {
		this._subSink.sink = this._router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((urlData: NavigationEnd) => {
				if (urlData.url.includes('tasks')) {
					this.shouldBackgroundColourInherit = true;
				} else {
					this.shouldBackgroundColourInherit = false;
				}
			});
	}
}
