import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Protask, ProtaskMessage } from 'src/app/core/models/domain.models';
import { IProtaskBoardResult2Dto, IProtaskMessageDto, IProtaskDetail2Dto } from 'src/app/core/models/dto.models';
import { ProtaskState } from 'src/app/core/models/enumerations';
import { DomainModelFactoryService } from 'src/app/core/services/domain-model-factory.service';
import { EndpointsService } from 'src/app/core/services/endpoints.service';
import { CreateTaskData } from '../create-protask/models/create-task.models';

type DrillDownAPIData = {
	protask: IProtaskDetail2Dto;
	messages: IProtaskMessageDto[];
};

@Injectable({ providedIn: 'root' })
export class TaskHttpService {
	constructor(
		private _http: HttpClient,
		private _endpointService: EndpointsService,
		private _modelFactory: DomainModelFactoryService
	) {}

	downloadActiveTasks(): Observable<Protask[]> {
		const url = this._endpointService.getActiveProtasks();
		return this._http
			.get<IProtaskBoardResult2Dto>(url)
			.pipe(map((dto) => dto.protasks.map((pd2) => this._modelFactory.buildProtask(pd2))));
	}

	downloadActiveTaskComments(taskId: number): Observable<ProtaskMessage[]> {
		const url = this._endpointService.getProtaskComments(taskId);
		return this._http
			.get<IProtaskMessageDto[]>(url)
			.pipe(map((dto) => dto.map((messageDto) => this._modelFactory.buildProtaskMessage(messageDto))));
	}

	downloadReportTask(taskId: number): Observable<Protask> {
		const url = this._endpointService.getProtaskDrilldownForReport(taskId);
		return this._http
			.get<DrillDownAPIData>(url, {
				headers: new HttpHeaders().set('Accept', 'application/json'),
			})
			.pipe(map((data) => this._modelFactory.buildProtask(data.protask)));
	}

	downloadReportComments(taskId: number): Observable<ProtaskMessage[]> {
		const url = this._endpointService.getProtaskDrilldownForReport(taskId);
		return this._http
			.get<DrillDownAPIData>(url, {
				headers: new HttpHeaders().set('Accept', 'application/json'),
			})
			.pipe(
				map((taskAndMessages) =>
					taskAndMessages.messages.map((messageDto) => this._modelFactory.buildProtaskMessage(messageDto))
				)
			);
	}

	downloadCustomFieldLookup(serviceId: number, templateFieldId: number, query: string): Observable<any[]> {
		const url = this._endpointService.lookupEndpoint(serviceId, templateFieldId, query);
		return this._http.get<any[]>(url);
	}

	postFormData(taskId: number, formData: FormData): Observable<IProtaskMessageDto> {
		const url = this._endpointService.postMessage(taskId);
		return this._http.post<IProtaskMessageDto>(url, formData, {
			headers: { Accept: 'application/json' },
		});
	}

	createTask(fields: CreateTaskData): Observable<any> {
		const url = this._endpointService.postProtask();
		return this._http.post<any>(url, { serviceId: fields.serviceId, ...fields });
	}

	cancelTask(protaskId: number): Observable<IProtaskDetail2Dto> {
		const url = this._endpointService.transitionProtaskState();
		return this._http.post<IProtaskDetail2Dto>(url, {
			protaskId,
			targetState: ProtaskState.Canceled,
		});
	}

	claimTask(protaskId: number): Observable<IProtaskDetail2Dto> {
		const url = this._endpointService.transitionProtaskState();
		return this._http.post<IProtaskDetail2Dto>(url, {
			protaskId,
			targetState: ProtaskState.InProgress,
		});
	}

	completeTask(protaskId: number): Observable<IProtaskDetail2Dto> {
		const url = this._endpointService.transitionProtaskState();
		return this._http.post<IProtaskDetail2Dto>(url, {
			protaskId,
			targetState: ProtaskState.Complete,
		});
	}

	closeTask(protaskId: number, rating: number, comments: string): Observable<IProtaskDetail2Dto> {
		const url = this._endpointService.transitionProtaskState();
		return this._http.post<IProtaskDetail2Dto>(url, {
			protaskId,
			rating,
			comments,
			targetState: ProtaskState.Closed,
		});
	}

	resubmitTask(protaskId: number): Observable<IProtaskDetail2Dto> {
		const url = this._endpointService.transitionProtaskState();
		return this._http.post<IProtaskDetail2Dto>(url, {
			protaskId,
			targetState: ProtaskState.InProgress,
		});
	}

	toggleTaskWatch(protaskId: number, newWatchStatus: boolean): Observable<any> {
		const url = this._endpointService.toggleProtaskWatch(protaskId, newWatchStatus);
		return this._http.post(url, { headers: { Accept: 'application/json' } });
	}
}
