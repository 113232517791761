import { DateRangeTypes, TimeZoneTypes } from './shared.enums';

export const DATE_RANGES: { value: DateRangeTypes; viewValue: string }[] = [
	{ value: DateRangeTypes.Today, viewValue: 'Today' },
	{ value: DateRangeTypes.Yesterday, viewValue: 'Since Yesterday' },
	{ value: DateRangeTypes.Week, viewValue: 'Week to Date' },
	{ value: DateRangeTypes.Month, viewValue: 'Month to Date' },
	{ value: DateRangeTypes.Quarter, viewValue: 'Quarter to Date' },
	{ value: DateRangeTypes.Year, viewValue: 'Year to Date' },
	{ value: DateRangeTypes.Custom, viewValue: 'Custom' },
];

export const TIME_ZONES: { value: TimeZoneTypes; viewValue: string }[] = [
	{ value: TimeZoneTypes.UTC, viewValue: 'UTC' },
	{ value: TimeZoneTypes.AlaskanStandardTime, viewValue: 'AKST' },
	{ value: TimeZoneTypes.PacificStandardTime, viewValue: 'PST' },
	{ value: TimeZoneTypes.MountainStandardTime, viewValue: 'MST' },
	{ value: TimeZoneTypes.CentralStandardTime, viewValue: 'CST' },
	{ value: TimeZoneTypes.EasternStandardTime, viewValue: 'EST' },
	{ value: TimeZoneTypes.AtlanticStandardTime, viewValue: 'AST' },
	{ value: TimeZoneTypes.NewfoundlandStandardTime, viewValue: 'NST' },
];
