import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormBuilder,
	FormControl,
	FormGroup,
	FormGroupDirective,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

// Models
import { TemplateField } from 'src/app/core/models/domain.models';

// Services
import { TaskConductorService } from 'src/app/protasks/services/task-conductor.service';
import { TemplateFormService } from 'src/app/shared/template-form.service';
import { TemplateType } from 'src/app/protasks/create-protask/models/create-task.types';
// import { TemplateType } from '../../protasks/create-protask/models/create-task.types';

@Component({
	selector: 'app-lookup-template',
	templateUrl: './lookup-template.component.html',
	styleUrls: ['./lookup-template.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class LookupTemplateComponent implements OnInit, OnDestroy {
	@Input() lookupTemplate: TemplateField;
	@Input() serviceRequestKey: number;

	lookupControlName: string;
	autoCompleteOptions: Observable<string[]>;

	private _parentForm: FormGroup;
	private _subSink = new SubSink();

	constructor(
		private _fb: FormBuilder,
		private _taskConductor: TaskConductorService,
		private _parentFormDirective: FormGroupDirective,
		private _templateFormService: TemplateFormService
	) {}

	ngOnInit(): void {
		this.setupLookupControl();
		this.setValidatorsOnControl();
		this.setupAutoComplete();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
		this._parentForm.removeControl(this.lookupControlName);
	}

	get lookupControl(): FormControl {
		return this._parentForm.controls[this.lookupControlName] as FormControl;
	}

	private setupLookupControl() {
		this.lookupControlName = this._templateFormService.generateTemplateFormName(
			TemplateType.Lookup,
			this.lookupTemplate.fieldId
		);
		this._parentForm = this._parentFormDirective.form;
		this._parentForm.addControl(this.lookupControlName, this._fb.control(null));
	}

	private setValidatorsOnControl(): void {
		const validatorFunctions: ValidatorFn[] = [];
		if (!!this.lookupTemplate.isRequired) {
			validatorFunctions.push(Validators.required);
		}
		if (!!this.lookupTemplate.maxCharacterLength) {
			const maxCharacterLength = this.lookupTemplate.maxCharacterLength;
			validatorFunctions.push(Validators.maxLength(maxCharacterLength));
		}
		this.lookupControl.setValidators(validatorFunctions);
	}

	private setupAutoComplete() {
		this.autoCompleteOptions = this.lookupControl.valueChanges.pipe(
			switchMap((value) =>
				this._taskConductor.loadCustomFieldLookup$(this.serviceRequestKey, this.lookupTemplate.fieldId, value)
			)
		);
	}
}
