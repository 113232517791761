import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

enum CustomBreakpoints {
	XSmall = '	(max-width: 599.98px)', // 4 Columns and 16px gutter
	Small = '(min-width: 600px) and (max-width: 719px)', // 8 Columns and 16px gutter
	LargeDevice = '(min-width: 720px) and (max-width: 839px)', // 8 Columns and 24px gutter
	Large = '(min-width: 840px)', // 12 Columns and 24px gutters

	StackCards = '(max-width: 1024px)', // Below 1024, stack
	RowCards = '(min-width: 1025px)', // Above 1024, put in row
}

const screenSizes = [
	CustomBreakpoints.XSmall, // 4 Columns and 16px gutter
	CustomBreakpoints.Small, // 8 Columns and 16px gutter
	CustomBreakpoints.LargeDevice, // 8 Columns and 24px gutter
	CustomBreakpoints.Large, // 12 Columns and 24px gutter

	CustomBreakpoints.StackCards, // Below 1024, stack
	CustomBreakpoints.RowCards, // Above 1024, put in row
];

const gutterBreakpoints = [
	CustomBreakpoints.XSmall,
	CustomBreakpoints.Small,
	CustomBreakpoints.LargeDevice,
	CustomBreakpoints.Large,
];

@Injectable({ providedIn: 'root' })
export class ViewportService {
	constructor(private _breakpointObserver: BreakpointObserver) {}

	observeViewportSize() {
		return this._breakpointObserver.observe(screenSizes).pipe(
			map((breakpointState) => {
				if (!!breakpointState?.matches) {
					const viewportSize = this.getMatchedViewportSizes(breakpointState);
					const gutterSize = this.determineGutterSize(viewportSize.gutterBreakpoint);
					const shouldStack = this.determineWhetherToStackOrNot(
						viewportSize.stackableBreakpoint
					);
					return {
						gutter: gutterSize,
						shouldStack: shouldStack,
					};
				}
			})
		);
	}

	private getMatchedViewportSizes(breakpointState: BreakpointState) {
		const completeViewportData = { gutterBreakpoint: null, stackableBreakpoint: null };
		for (const screenSize in breakpointState.breakpoints) {
			const observerBreakpointStateMatch = breakpointState.breakpoints[screenSize];
			if (!!observerBreakpointStateMatch) {
				const matchedGutterColumnBreakpoint = gutterBreakpoints.find(
					(breakpoint) => screenSize === breakpoint
				);
				if (!!matchedGutterColumnBreakpoint) {
					completeViewportData.gutterBreakpoint = matchedGutterColumnBreakpoint;
				} else {
					completeViewportData.stackableBreakpoint = screenSize;
				}
			}
		}
		return completeViewportData;
	}

	private determineGutterSize(viewportSize: string) {
		switch (viewportSize) {
			case Breakpoints.XSmall:
			case CustomBreakpoints.Small:
				return 16;
			case CustomBreakpoints.LargeDevice:
			case CustomBreakpoints.Large:
				return 24;
			default:
				return 16;
		}
	}

	private determineWhetherToStackOrNot(viewportSize: string) {
		switch (viewportSize) {
			case CustomBreakpoints.StackCards:
				return true;
			case CustomBreakpoints.RowCards:
				return false;
			default:
				true;
		}
	}
}
