import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { AppComponent } from 'src/app/app.component';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { TaskConductorService } from 'src/app/protasks/services/task-conductor.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.css'],
})
export class LogoutComponent {
	constructor(
		private _dialogRef: MatDialogRef<AppComponent>,
		private _taskConductor: TaskConductorService,
		private _profileService: ProfileService,
		private _msalService: MsalService
	) {}

	logout(): void {
		this._taskConductor.emptyTasksInStore();
		this._msalService.logoutRedirect();
		this._profileService.clearProfileData();
		this._dialogRef.close();
	}

	cancel(): void {
		this._dialogRef.close();
	}
}
