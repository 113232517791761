<mat-card>
	<div class="flex-column">
		<mat-icon color="primary">{{ iconName }}</mat-icon>
		<h6>{{ title }}</h6>
	</div>
	<mat-card-content>
		<span class="body-1">{{ subtitle }}</span>
	</mat-card-content>
	<mat-grid-tile-footer class="footer">
		<button mat-button color="primary" (click)="navigateToReport()">
			<span class="button" style="padding-right: 8px">View</span>
			<mat-icon>arrow_forward</mat-icon>
		</button>
	</mat-grid-tile-footer>
</mat-card>
