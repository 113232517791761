export const environment = {
	production: true,

	// General
	paginatorPageSizeKey: 'PAGINATORPREFS',
	taskboardSortValues: 'SORTVALUES',
	currentVersion: '3.2.2',

	// Profile Storage Keys
	idTokenKey: 'idToken',
	userOid: 'oid',
	userName: 'name',
	accessTokenKey: 'accessToken',
	myAccessProfileKey: 'myAccess',
	userProfilePictureUrlKey: 'ProfilePictureUrl',

	// Submitted / Received Task Keys
	submittedTablePrefsKey: 'SubmitttedTablePrefsKey',
	submittedActiveOrgskey: 'SubmittedActiveOrgsKey',
	submittedActiveTableColumnsKey: 'SubmittedActiveTableColumnsKey',
	receivedTablePrefsKey: 'ReceivedTablePrefsKey',
	receivedActiveOrgskey: 'ReceivedActiveOrgsKey',
	receivedActiveTableColumnsKey: 'ReceivedActiveTableColumnsKey',
	taskRouteKey: 'taskRouteKey',

	// Reports
	activityReportPrefs: 'ActivityReportPrefs',
	activityReportActiveTableColumns: 'ActivityReportTableColumns',
	activityReportActiveOrgs: 'ActivityReportActiveOrgs',
	activityReportAtRiskToggle: 'ActivityReportToggle',
	dashboardAtRiskToggle: 'DasboardAtRiskToggle',
	dashboardSidebarToggle: 'DashboardSidebarToggle',
	dashboardFilter: 'DashboardFilter',
	dashboardBlinkRange: 'DashboardBlinkRange',
	dashboardActiveTableColumns: 'DashboardActiveTableColumns',
	dashboardActiveOrgs: 'DashboardActiveOrgs',
	dashboardActiveSortBy: 'DashboardActiveSortBy',
	serviceRatingDateRangeKey: 'R3DATERANGE',
	serviceRatingPrefsKey: 'R3PREFS',
	serviceRatingActiveTableColumns: 'ServiceRatingActiveTableColumns',
	serviceRatingActiveSortBy: 'ServiceRatingActiveSortBy',
	serviceRatingPaginatorSize: 'ServiceRatingPaginatorSize',
	geofencePaginatorSize: 'GeofencePaginatorSize',
	geofenceActiveTableColumns: 'GeofenceActiveTableColumns',
	geofenceActiveSortBy: 'GeofenceActiveSortBy',
	geofenceDateRangeKey: 'R4DATERANGE',
	geofenceTimeZoneKey: 'R4TIMEZONE',

	// MSAL - Dev
	clientId: 'e0581ddc-99f5-4c94-b6a0-0421f4d0e854',
	redirectUri: 'https://dev.gorightfleet.com/home',
	authority: 'https://login.microsoftonline.com/71c12816-1eed-4c84-88cd-8d46e60fdbf0',
	signalrConnectionString: 'https://cana-mirlin-dev-distribution-fa.azurewebsites.net/api',
	profileUrl: 'https://graph.microsoft.com/v1.0/me',
	profilePictureUrl: 'https://graph.microsoft.com/v1.0/users/[user_oid]/photos/48x48/$value',
	webApiUrl: 'https://cana-mirlin-dev-web-api.azurewebsites.net',

	// MSAL - Prod
	// clientId: '440bf1f3-1779-4bad-910d-c70f61aa9f82',
	// redirectUri: 'https://connect.mirlin.io/',
	// authority: 'https://login.microsoftonline.com/71c12816-1eed-4c84-88cd-8d46e60fdbf0',
	// signalrConnectionString: 'https://cana-mirlin-prd-distribution-fa.azurewebsites.net/api',
	// profileUrl: 'https://graph.microsoft.com/v1.0/me',
	// profilePictureUrl: 'https://graph.microsoft.com/v1.0/users/[user_oid]/photos/48x48/$value',
	// webApiUrl: 'https://cana-mirlin-prd-web-api.azurewebsites.net',
};
