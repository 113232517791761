import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TemplateFieldType } from '../core/models/enumerations';
import { Template } from '../core/types/domain.types';
import {
	LocationForm,
	LocationTemplateField,
	TextTemplateField,
} from '../protasks/create-protask/models/create-task.models';
import { LocationFormProperties, TemplateType } from '../protasks/create-protask/models/create-task.types';
import { GpsFixStatus } from '../reports/geofence-report/models/geofence.enums';

@Injectable({ providedIn: 'root' })
export class TemplateFormService {
	generateTemplateFormName(templateType: TemplateType, fieldId: number) {
		return `${templateType}-${fieldId}`;
	}

	checkForTemplateTypeExistence(templateFieldType: TemplateFieldType, template: Template) {
		const filteredTemplates = template.templateFields.find((field) => field.fieldType === templateFieldType);
		return !!filteredTemplates ? true : false;
	}

	getTextTemplateValues(form: FormGroup, template: Template) {
		const textTemplateData: TextTemplateField = {};
		for (const controlName in form.controls) {
			if (controlName.includes(TemplateType.Text)) {
				const controlValue: string = form.get(controlName).value;
				const controlFieldId = this.getControlsFieldId(controlName);
				const matchingTemplateWithControl = template.templateFields
					.filter((template) => template.fieldType === TemplateFieldType.TextOnly)
					.find((textTemplate) => textTemplate.fieldId === controlFieldId);
				textTemplateData[matchingTemplateWithControl.fieldId] = controlValue;
			}
		}
		return textTemplateData;
	}

	getLookupTemplateValues(form: FormGroup, template: Template) {
		const lookupTemplateData: TextTemplateField = {};
		for (const controlName in form.controls) {
			if (controlName.includes(TemplateType.Lookup)) {
				const controlValue: string = form.get(controlName).value;
				const controlFieldId = this.getControlsFieldId(controlName);
				const matchingTemplateWithControl = template.templateFields
					.filter((template) => template.fieldType === TemplateFieldType.TextWithLookup)
					.find((lookupTemplate) => lookupTemplate.fieldId === controlFieldId);
				lookupTemplateData[matchingTemplateWithControl.fieldId] = controlValue;
			}
		}
		return lookupTemplateData;
	}

	getLocationTemplateValues(form: FormGroup) {
		let locationTemplateData: LocationTemplateField;
		for (const controlName in form.controls) {
			if (controlName.includes(TemplateType.Location)) {
				const locationFormGroup = form.get(controlName) as FormGroup;
				const controlFieldId = this.getControlsFieldId(controlName);
				locationTemplateData = {
					[controlFieldId]: {
						fs: GpsFixStatus.WebApp,
						ad: null,
						ci: null,
						l: null,
						lo: null,
						sp: null,
					},
				};
				for (const locationControlName in locationFormGroup.controls) {
					const controlValue: string = locationFormGroup.get(locationControlName).value;
					this.addRelatedPropertyToLocationObject(
						locationControlName,
						controlValue,
						locationTemplateData[controlFieldId]
					);
				}
			}
		}
		return locationTemplateData;
	}

	getFormattedTemplateFields(form: FormGroup, template: Template) {
		const formattedFields: {
			textFields?: TextTemplateField;
			locationFields?: LocationTemplateField;
		} = {};

		// If it does, check which type of templates exist and get their values
		const doesTextTemplateExist = this.checkForTemplateTypeExistence(TemplateFieldType.TextOnly, template);
		const doesLookupTemplateExist = this.checkForTemplateTypeExistence(TemplateFieldType.TextWithLookup, template);
		const doesLocationTemplateExist = this.checkForTemplateTypeExistence(TemplateFieldType.Location, template);

		// Go through each state and attach the data if the template exists per type
		if (doesTextTemplateExist) {
			const textFields = this.getTextTemplateValues(form, template);
			formattedFields.textFields = textFields;
		}
		if (doesLookupTemplateExist) {
			const lookupFields = this.getLookupTemplateValues(form, template);
			formattedFields.textFields = { ...lookupFields, ...formattedFields.textFields };
		}
		if (doesLocationTemplateExist) {
			const locationFields = this.getLocationTemplateValues(form);
			formattedFields.locationFields = locationFields;
		}
		return formattedFields;
	}

	private getControlsFieldId(controlName: string) {
		const stringFieldId: string = controlName.substring(controlName.indexOf('-') + 1);
		return parseInt(stringFieldId);
	}

	private addRelatedPropertyToLocationObject(
		controlName: string,
		controlValue: string,
		locationObject: LocationForm
	) {
		switch (controlName) {
			case LocationFormProperties.Address:
				locationObject.ad = controlValue;
				break;
			case LocationFormProperties.City:
				locationObject.ci = controlValue;
				break;
			case LocationFormProperties.Latitude:
				locationObject.l = +controlValue;
				break;
			case LocationFormProperties.Longitude:
				locationObject.lo = +controlValue;
				break;
			case LocationFormProperties.Region:
				locationObject.sp = controlValue;
				break;
			default:
				break;
		}
	}
}
