import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HttpRequestService {

	setAuthorizationHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
		const headers = req.headers.set('Authorization', `Bearer ${token}`);
		return req.clone({
			headers
		})
	}

}
