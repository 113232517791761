import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[appBgRandomColor]',
})
export class BgRandomColorDirective implements OnInit {
	@HostBinding('style.background-color') backgroundColor: string;
	@Input() defaultColor?: string;

	ngOnInit(): void {
		this.setBackgroundColor();
	}

	private setBackgroundColor() {
		if (this.defaultColor) {
			this.backgroundColor = this.defaultColor;
			return;
		}
		const randomColor = Math.floor(Math.random() * 16777215).toString(16);
		this.backgroundColor = '#' + randomColor;
	}
}
