<section class="flex-column">
	<mat-form-field class="full-width" appearance="outline">
		<mat-label>{{ lookupTemplate.label }}</mat-label>
		<input
			type="text"
			matInput
			[formControl]="lookupControl"
			[matAutocomplete]="auto"
		/>
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
			<mat-option
				*ngFor="let option of autoCompleteOptions | async"
				[value]="option.t1"
			>
				<strong>{{ option.t1 }}</strong> {{ option.t2 }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</section>
