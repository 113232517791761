import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

export class LatLngDialogResult {
	latitude: number;
	longitude: number;

	constructor(lat: number, lng: number) {
		this.latitude = lat;
		this.longitude = lng;
	}
}

class ValidationResult {
	pass: boolean = false;
	reason: string = '';
	l: number;
	lo: number;
}

@Component({
	selector: 'app-lat-long-dialog',
	templateUrl: './lat-long-dialog.component.html',
	styleUrls: ['./lat-long-dialog.component.css'],
})
export class LatLongDialogComponent implements OnInit {
	latLngForm: FormGroup;
	latLngString: string;
	reason: string = '';

	constructor(public dialogRef: MatDialogRef<LatLongDialogComponent>) {}

	ngOnInit(): void {
		this.latLngForm = new FormGroup({
			latLngString: new FormControl(null, Validators.required),
		});
	}

	onCancelClick(): void {
		this.dialogRef.close();
	}

	onOkClick(): void {
		this.reason = '';
		const result = this.validateLatLng();
		if (result.pass) {
			this.dialogRef.close(new LatLngDialogResult(result.l, result.lo));
		} else {
			this.reason = result.reason;
		}
	}

	validateLatLng(): ValidationResult {
		const ll = this.latLngForm.value.latLngString.split(',');
		const result = new ValidationResult();

		if (ll.length !== 2) {
			result.reason = 'There must be two numbers separated by a comma.';
			return result;
		}

		let l = Number(ll[0]);
		let lo = Number(ll[1]);

		if (isNaN(l) || isNaN(lo)) {
			result.reason =
				'Latitude and/or longitude must be in the purely numeric format. e.g. 43.160024';
			return result;
		}

		// Swap latitude and longitude if they were entered in reverse order
		if (l < 0 && lo > 0) {
			l = l + lo;
			lo = l - lo;
			l = l - lo;
		}

		// Make sure latitude and longitude are in North America (at this time anyway)
		if (l < 7.2 || l > 75) {
			result.reason = 'Latitude is invalid';
			return result;
		}

		if (lo < -168 || lo > -52) {
			result.reason = 'Longitude is invalid';
			return result;
		}

		result.l = l;
		result.lo = lo;
		result.pass = true;
		return result;
	}
}
