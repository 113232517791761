import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-user-profile-picture',
	templateUrl: './user-profile-picture.component.html',
	styleUrls: ['./user-profile-picture.component.scss'],
})
export class UserProfilePictureComponent implements OnInit {
	userProfileNameInitials?: string;
	randomColor?: string;

	@Input() name: string | null;
	@Input() profilePicUrl: string | null;
	@Input() defaultColor: string | null;

	ngOnInit(): void {
		this.userProfileNameInitials = this.getUserNameInitials(this.name);
	}

	private getUserNameInitials(userName: string): string {
		return userName
			?.split(' ')
			.map((value, index) => {
				if (index < 2) return value.substring(0, 1).toUpperCase();
				return;
			})
			.join('');
	}
}
