import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TaskConductorService } from '../protasks/services/task-conductor.service';

@Injectable({ providedIn: 'root' })
export class FileUploaderService {
	private _uniqueId = 0;

	constructor(private _taskConductor: TaskConductorService) {}

	generateFileFormName() {
		return `fileList-${this._uniqueId++}`;
	}

	getAttachedFiles(fileListForm: FormArray) {
		const listOfFiles: File[] = [];
		fileListForm.controls.forEach((control) => listOfFiles.push(control.value));
		return listOfFiles;
	}

	getAllFileListForms(parentForm: FormGroup) {
		const listOfFileUploaderForms: FormArray[] = [];
		for (const controlName in parentForm.controls) {
			if (controlName.startsWith('fileList')) {
				const control = parentForm.get(controlName) as FormArray;
				listOfFileUploaderForms.push(control);
			}
		}
		return listOfFileUploaderForms;
	}

	async uploadFiles(fileListForm: FormArray) {}
}
