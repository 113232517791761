import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class DynamicFilterMiddlewareService {
	private _subjectSenderForm = new Subject<any>();
	private _subjectFilterData = new Subject<any>();

	sendFiltersForm(filters: any) {
		of([])
			.pipe(delay(25))
			.subscribe(() => {
				this._subjectSenderForm.next(filters);
			});
	}

	deleteFiltersForm() {
		this.sendFiltersForm(undefined);
	}

	getFiltersForm(): Observable<any> {
		return this._subjectSenderForm.asObservable();
	}

	sendFiltersValue(filtersValue: any) {
		this._subjectFilterData.next(filtersValue);
	}

	getFiltersValue(): Observable<any> {
		return this._subjectFilterData.asObservable();
	}
}
