<div
	(mouseover)="handleMouseOver()"
	(mouseleave)="handleMouseLeave()"
	class="image-box"
	[style.height.px]="!!boxHeight ? boxHeight : 100"
	[style.width.px]="!!boxWidth ? boxWidth : 100"
>
	<div [ngClass]="{ hover: isCursorHovering }" style="height: 100%" (click)="openImagePreviewDialog()">
		<!-- Image File -->
		<img *ngIf="!!imagePath; else notAnImage" class="image-preview" src="{{ imagePath }}" alt="Image Preview" />

		<!-- Non Image File -->
		<ng-template #notAnImage>
			<div class="file-box">
				<mat-icon>file_copy</mat-icon>
				<span class="caption">{{ fileName }}</span>
			</div>
		</ng-template>
	</div>

	<div *ngIf="isCursorHovering" class="centered">
		<mat-icon (click)="deleteFile()"> delete_forever </mat-icon>
	</div>
</div>
