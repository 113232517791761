import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterBase } from '../dynamic-filter/models/filter-base';

@Injectable({
	providedIn: 'root',
})
export class DynamicFilterService {
	constructor() {}

	toFormGroup(filters: FilterBase<any>[]) {
		const group: any = {};
		filters?.forEach((filter) => {
			group[filter.key] = filter.required
				? new FormControl(filter.value || '', Validators.required)
				: new FormControl(filter.value || '');
		});
		return new FormGroup(group);
	}
}
