import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { RouteSegment } from '../models/shared.types';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	@Input() routeSegmentTitleToRemove?: string;
	listOfRouteSegments: RouteSegment[] = [];
	private _subSink = new SubSink();

	constructor(private _router: Router, private _breadcrumbService: BreadcrumbService) {}

	ngOnInit(): void {
		this._subSink.sink = this.observeCurrentUrl().subscribe(
			(event: NavigationEnd) => (this.listOfRouteSegments = this.getTokenizedUrl(event.url))
		);
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	navigateToPath(url: string) {
		const urlWithoutLastSlash = url.slice(0, -1);
		this._router.navigateByUrl(urlWithoutLastSlash);
	}

	private observeCurrentUrl() {
		return this._router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			startWith(this._router)
		);
	}

	private getTokenizedUrl(url: string) {
		const tokenizedUrl = this._breadcrumbService.tokenizeUrl(url);
		if (!!this.routeSegmentTitleToRemove) {
			const indexOfSegment = [...tokenizedUrl].findIndex(
				(segment) => segment.title.toLowerCase() === this.routeSegmentTitleToRemove
			);
			if (indexOfSegment >= 0) {
				tokenizedUrl.splice(indexOfSegment, 1);
			}
		}
		return tokenizedUrl;
	}
}
